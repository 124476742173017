import React, { useState, useEffect, useRef } from "react";
import bed from "../../assets/images/icons/bed.png";
import ward from "../../assets/images/icons/ward.png";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import AddBuilding from "./AddBuilding";
import AddFloor from "./AddFloor";
import AddRooms from "./AddRooms";
import { APIURL, TZ } from "../../Global";
import { useAlert } from "react-alert";
import moment from "moment";
import axios from "axios";
import SimpleBar from 'simplebar-react';
import "simplebar-react/dist/simplebar.min.css";

import AddWards from "./AddWards";
import { NavDropdown } from "react-bootstrap";
import AddRoom from "./AddRoom";
import AddWard from "./AddWard";
const HospitalMngmnt = () => {
  const [buildings, setBuildings] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [refresh1, setRefresh1] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [wards, setWards] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [addBuildingPopupVisible, setAddBuildingPopupVisible] = useState(false);
  const [floors, setFloors] = useState([]);
  const [addFloorPopupVisible, setAddFloorPopupVisible] = useState(false);
const [addRoom,setAddRoom]=useState(false)
const[addWard,setAddWard]=useState(false)

  const handleBuildingClick = (building, build_name) => {
    setSelectedBuilding(building);

  };
  const floorShow = () => {
    setAddFloorPopupVisible(false);
    setRefresh1(!refresh1)
    
  };
  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");


    axios
      .get(
        `${APIURL}/api/v1/service-provider/add-rooms/?building=${selectedBuilding}&&floor=${selectedFloor}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {

          setRooms(res.data);
        }
      });

    axios
      .get(
        `${APIURL}/api/v1/service-provider/add-wards/?building=${selectedBuilding}&&floor=${selectedFloor}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {

          setWards(res.data);
        }
      });

  }, [selectedFloor,refresh2])
  useEffect(() => {

    if (selectedBuilding !== "") {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");

      axios
        .get(`${APIURL}/api/v1/service-provider/floor/?building=${selectedBuilding}&${TZ}`, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setFloors(res.data);
            setSelectedFloor(res.data[0].id)


          }
        });
    }
  }, [selectedBuilding,refresh1]);
  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/building/?&${TZ}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setBuildings(res.data);
          setSelectedBuilding(res.data[0].id);

        }
      });
  }, [refresh]);
  const FloorPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddFloor handle={floorShow} data={props.data} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };
  const BuildingPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
          <AddBuilding handle={buildingShow} />
        </Modal.Body>

      </Modal>
    );
  };
  const handleFloorClick = (floor, building, floor_name) => {
    setSelectedFloor(floor);


  };
  const buildingShow = () => {
    setAddBuildingPopupVisible(false);
    setRefresh(!refresh);
  };
  return (<>
    <div className="row" style={{ margin: "1%" }}>
      <div className="col-2">
        <h5>Facility management</h5>
        <SimpleBar style={{ maxHeight: '252px' }}>
          {buildings.map(
            (building, index) =>
              <>
                <button
                  style={{
                    textTransform: "capitalize",
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    border: "0.5px solid #2B69A3",
                    borderRadius: "10px",
                    marginBottom: "1%",
                    padding: "3%",
                    flexShrink: "0",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    textAlign: "left",
                    color:
                      selectedBuilding === building.id
                        ? "white"
                        : "#2B69A3",
                    backgroundColor:
                      selectedBuilding === building.id ? "#2B69A3" : "white",



                  }}
                  key={building.id}

                  onClick={() =>
                    handleBuildingClick(building.id, building.build_name)
                  }
                ><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 20 20"><path fill="currentColor" d="M6.75 6.5a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5m.75 2.25a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0m-.75 3.75a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5m3.75-6.75a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0M9.75 9.5a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5m.75 2.25a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0m2.25.75a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5M4.5 18a.5.5 0 0 1-.5-.5v-14A1.5 1.5 0 0 1 5.5 2h6A1.5 1.5 0 0 1 13 3.5V8h1.5A1.5 1.5 0 0 1 16 9.5v8a.5.5 0 0 1-.5.5zM5 3.5V17h2v-2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5V17h2V9.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0-.5.5M12 15h-1.5v2H12zm-2.5 0H8v2h1.5z" /></svg>&nbsp;&nbsp;
                  {building.build_name}

                </button><br /></>

          )}
        </SimpleBar>

        <button
          style={{
            textTransform: "capitalize",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
            border: "0.5px solid #2B69A3",
            borderRadius: "20px",
            marginBottom: "1%",
            marginTop: "10%",
            padding: "3%",
            flexShrink: "0",
            fontSize: "14px",
            fontFamily: "Poppins",
            color: "white",
            backgroundColor: "#2B69A3",
          }}


          onClick={() =>
            setAddBuildingPopupVisible(true)
          }
        >
          <b>Add New Building</b>
        </button>
      </div>
      <div className="col-10">
        <div className="row">
          <div className="col-10">
            <SimpleBar style={{}}>
              {floors.map(
                (floor, index) =>
                  <>
                    <button
                      className="col-2"
                      key={index}
                      style={{
                        textTransform: "capitalize",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        // whiteSpace: 'nowrap',
                        // width: '90%',
                        border: selectedFloor === floor.id ? "1.5px solid #2B69A3" : "0.5px solid #2B69A3",
                        borderRadius: "10px",
                        marginBottom: "1%",
                        padding: "0.5%",
                        flexShrink: "0",
                        fontSize: "14px",
                        fontFamily: "Poppins",

                        backgroundColor:
                          selectedFloor === floor.id ? "#E6EEF8" : "white",


                      }}
                      onClick={() =>
                        handleFloorClick(floor.id, floor.building, floor.floor_name)
                      }
                    >
                      {floor.floor_name}
                    </button><span>&nbsp;&nbsp;</span></>

              )}
            </SimpleBar>
          </div>
          <div className="col-2">
            <button
              style={{
                textTransform: "capitalize",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                border: "0.5px solid #2B69A3",
                borderRadius: "20px",
                marginBottom: "1%",
                // marginTop:"10%",
                width: "100%",
                padding: "3%",
                flexShrink: "0",
                fontSize: "14px",
                fontFamily: "Poppins",
                color: "white",
                backgroundColor: "#2B69A3",
              }}


              onClick={() =>
                setAddFloorPopupVisible(true)
              }
            >
              <b>Add new floor</b>
            </button>
          </div>
        </div>
        {floors.length>0&&   <div className="">
          <div className="floordetails">
          <div style={{ display: "flex" }}>
            <div style={{ borderBottom: "3px solid #25314C", marginBottom:"2%" }}>
              <img
                src={bed}
                alt="Room"
                style={{ width: "20%", marginTop: "-12%" }}
              />&nbsp;&nbsp;
              <span>{rooms.length}-Rooms </span>
           
            </div>&nbsp;&nbsp;&nbsp;
            <button
          style={{
           marginLeft:"78%",
            textTransform: "capitalize",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '15%',
            border: "0.5px solid #2B69A3",
            borderRadius: "20px",
           marginBottom:"1%",
          
            fontSize: "14px",
            fontFamily: "Poppins",
            color: "white",
            backgroundColor: "#2B69A3",
          }}


          onClick={() =>
            setAddRoom(!addRoom)
          }
        >
          <b>Add New Room</b>
        </button>
          </div>
{addRoom&&
<AddRoom building={selectedBuilding}  floor={selectedFloor} onSuccess={() =>{setRefresh2(!refresh2)}}/>
}
<SimpleBar className="" style={{ maxHeight: '500px' }}>
            <table
              style={{
                // margin: "20px 0",
                // borderCollapse: "separate",
                // borderSpacing: "0 5px",
                width: "100%",
                height: "auto",
                textTransform:"capitalize"
              }}
            >
            {rooms.length>0&&  <thead>
                <tr
                  style={{
                    height: "43px",
                    flexShrink: "0",
                    borderRadius: "6px",
                    backgroundColor: "var(--dark-teal)",
                  }}
                >
                  <th className="col-1" style={{ color: "white" }}>
                    Sl.No.
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Room Name
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Number
                  </th>
                 
                  <th className="col-2" style={{ color: "white" }}>
                    Type
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Price
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Date Added
                  </th>
                </tr>
              </thead>}
              <tbody>
             
                {rooms.map((room, index) => (
                  <tr className=""  style={{
                    
                  }}key={room.id} >
                    <td className="col-1 ser_body">{index + 1}</td>
                    <td className="col-2 ser_body">{room.room_name}</td>
                    <td className="col-2 ser_body">{room.room_number}</td>
                   
                    <td className="col-2 ser_body">{room.room_type==="vip"?"VIP":room.room_type==="vvip"?"VVIP":room.room_type}</td>
                    <td className="col-2 ser_body">
                      <i class="fa fa-eur" aria-hidden="true"></i>
                      {room.room_price}
                    </td>
                    <td className="col-2 ser_body">
                      {moment(room.created_at).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                ))}
                    
              </tbody>
            </table>
            </SimpleBar><br/>
<br/>

          <div style={{ display: "flex", }}>
            <div style={{ borderBottom: "3px solid #25314C", marginBottom:"2%" }}>
              <img
                src={ward}
                alt="Ward"
                style={{ width: "22%", marginTop: "-12%" }}
              />&nbsp;&nbsp;
              <span>{wards.length}-Wards </span>
            </div>
            &nbsp;&nbsp;&nbsp;
            <button
          style={{
            marginLeft:"78%",
            textTransform: "capitalize",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '15%',
            border: "0.5px solid #2B69A3",
            borderRadius: "20px",
           marginBottom:"1%",
          
            fontSize: "14px",
            fontFamily: "Poppins",
            color: "white",
            backgroundColor: "#2B69A3",
          }}


          onClick={() =>
            setAddWard(!addWard)
          }
        >
          <b>Add New Ward</b>
        </button>
          </div>
          {addWard&&
<AddWard building={selectedBuilding}  floor={selectedFloor} onSuccess={() =>{setRefresh2(!refresh2)}}/>
}
<SimpleBar className="" style={{ maxHeight: '500px' }}>

              { wards.length > 0 && 
              <div
              class="row ser_header"
              style={{ textAlign: "center", width: "100%", paddingTop: "1%",margin:"auto" }}
            >
                <h6 className="col-1" > Sl.No.</h6>
                <h6 className="col-2" >Ward Name</h6>
                <h6 className="col-2">Ward Number</h6>
                <h6 className="col-1">Bed Count</h6>
                <h6 className="col-3">Ward Type</h6>
                <h6 className="col-1">Bed Price</h6>
                <h6 className="col-2" >Date of Added</h6>
               
            </div>}

            {
               wards.length > 0
               ? wards.map((item, index) => {
                   const bedButtons = [];
         
                   for (let i = 1; i <= item.bed_capacity; i++) {
                     bedButtons.push(
                       <button key={i} className="bed-button">
                         Bed {i}
                       </button>
                     );
                   }
         
                   return (
                     <>
                       <div
                         className=""
                         style={{
                           width: "100%",
                           textTransform:"capitalize"
                         }}
                        
                       >
                         <div
                           class="row"
                           style={{ textAlign: "left" ,paddingLeft:"1.25%",paddingRight:"1.3%"}}
                         >
                            <div class="col-1 ser_body">
                             {index+1}
                           </div>
                           <div class="col-2 ser_body" >
                             {item.ward_name}
                           </div>
                           <div class="col-2 ser_body" >
                             {item.ward_number}
                           </div>
                           <div class="col-1 ser_body">
                             {item.bed_capacity}
                           </div>
                           <div class="col-3 ser_body" >
                             {item.ward_type==="vip"?"VIP":item.ward_type==="vvip"?"VVIP":item.ward_type}
                           </div>
                           <div class="col-1 ser_body" >
                           <i class="fa fa-eur" aria-hidden="true"></i> {item.bed_price}
                           </div>
                           <div class="col-2 ser_body" >
                             {moment(item.created_at).format("YYYY-MM-DD")}
                           </div>
                         </div>
                       </div>
                       
                     </>
                   );
                 })
               : null
            }
           </SimpleBar>
        </div>
        </div>}
      </div>
    </div>
    {addBuildingPopupVisible ? (
      <BuildingPopup
        show={addBuildingPopupVisible}
        onHide={() => {
          setAddBuildingPopupVisible(false);
        }}
      />
    ) : null}
    {addFloorPopupVisible ? (
      <FloorPopup
        show={addFloorPopupVisible}
        data={selectedBuilding}
        onHide={() => {
          setAddFloorPopupVisible(false);
        }}
      />
    ) : null}
  </>
  )
}
export default HospitalMngmnt;
