import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./featurelist.css";
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
import AddComo from "./AddComo";
import EditComo from "./EditComo";
import LoadingOverlay from "react-loading-overlay";
import Pagination from "react-js-pagination";
import { useAlert } from "react-alert";

function Comorbidites() {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [featureList, setFeatureList] = useState([]);
  const [editShow, setEditShow] = useState(false);
  const [current, setCurrent] = useState();
  const [refresh, setRefresh] = useState(false);
  const [addRentalShow, setAddRentalShow] = useState(false);
  const [category, setCategory] = useState(2);
  const [expand, setExpand] = useState();
  const [flag, setFlag] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");
  const alert = useAlert();
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    bindComorbidites();
  }, [refresh]);

  const bindComorbidites = () => {
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/staff/master-comorbidities/?${TZ}`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {
          setLoading(false);
          const datas = res.data;
          console.log(datas);
          setFeatureList(datas);
          //console.log(featureList)
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeletePopUp = (id) => {
    //  e.preventDefault();
    setDeleteIndex(id);
    setDeleteShow(true);
  };

  const handleDelete = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    //  const dataToSend = {
    //   "id" : item
    // }

    axios
      .delete(
        `${APIURL}/api/v1/staff/master-comorbidity-detail/${deleteIndex}/`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.status == 204) {
          alert.success('Deleted successfully!')
          
        } else {
          console.log("else called ");
          alert.error('Error occured')
        }

        setDeleteIndex("");
        setRefresh(!refresh)
        setDeleteShow(false);
      })
      .catch((err) => {
        console.log("catch called");
        // setSubmitMsg("error");
        // setErrorSubmit(true);
      });
  };

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="modal-popout"
        >
          <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
            <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
            <label style={{ margin: '20px auto', fontSize: '18px', color: '#4e4e4e' }}>Please confirm to delete! </label>
            <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
              <button type="button" className='swirl-butns' style={{ margin: '10px 4px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
              <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 4px', backgroundColor: '#2b69a3' }} onClick={()=>handleDelete(deleteIndex)} >Confirm </button>
            </div>
          </Modal.Body>
      </Modal>
    );
  };

  const modalClose = () => {
    setEditShow(false);
    setRefresh(!refresh);
  };

  const rentalModalClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
    setFlag(true);
  };

  const notProceedClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
  };

  const handleClose = () => {
    bindComorbidites();
    setAddRentalShow(false);
    setEditShow(false);
  };

  const AddRentalPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Body>
          <AddComo
            modalClose={rentalModalClose}
            notProceedClose={notProceedClose}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const EditPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Body>
          <EditComo data={current} modalClose={modalClose} />
        </Modal.Body>
      </Modal>
    );
  };

  const handleEdit = (item) => {
    // const list = featureList[index];
    setCurrent(item);
    setEditShow(true);
  };

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand();
    }
  };
  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let data = featureList
    .sort((a, b) => {
      if (a.comorbidity_name.toLowerCase() < b.comorbidity_name.toLowerCase())
        return -1;
      if (a.comorbidity_name.toLowerCase() > b.comorbidity_name.toLowerCase())
        return 1;
      return 0;
    })
    .filter((value) => {
      if (searchTerm === "") {
        return value;
      } else if (
        value.comorbidity_name.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return value;
      }
    });

  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current1 = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;
  let dataDisplay = [];

  dataDisplay =
    category === 2 ? (
      featureList.length > 0 ? (
        current1.map((item, index) => {
          return (
            <div
              className="care-features"
              key={item.id}
              style={{ cursor: "pointer" }}
              onClick={() => handleDetail(index)}
            >
              <div className="feature-data">
                <h5 className="feature-title">
                  <b>
                    {" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDetail(index)}
                    >
                      {(currentPage - 1) * 10 + index + 1}.
                      {item.comorbidity_name}<span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span><span style={{fontSize:"20px", color: "green"}}>(stage-{item.comorbidity_stage})(Type:{item.comorbidity_type})</span>
                    </span>
                    <i
                      className={
                        expand === index
                          ? "fa fa-angle-up fa-fw"
                          : "fa fa-angle-down fa-fw"
                      }
                      style={{ color: "#6788bc " }}
                    ></i>
                  </b>

                  <i
                    class="fas fa-trash-alt"
                    onClick={() => handleDeletePopUp(item.id)}
                    style={{ float: "right", color: "red", cursor: "pointer" }}
                  ></i>
                  <i
                    class="fas fa-pencil-alt"
                    onClick={() => handleEdit(item)}
                    aria-hidden="true"
                    style={{
                      float: "right",
                      paddingRight: "25px",
                      color: "#5a9569",
                      cursor: "pointer",
                    }}
                  ></i>
                </h5>
              </div>

              {expand === index ? (
                <>
                  <div className="seperate-content" />
                  <div className="row-of-features">
                    <div className="col-of-features">
                      <h6>Comorbidity Type : {item.comorbidity_type}</h6>
                      <h6>Comorbidity Index: {item.comorbidity_index}</h6>
                      <h6>Comorbidity Stage: {item.comorbidity_stage}</h6>
                      <h6>Created at : {item.created_at}</h6>
                      <h6>Updated at : {item.modified_at}</h6>
                    </div>
                  </div>
                  {/* <button onClick={()=>handleEditInventory(index)} style={{alignSelf:'flex-end'}} className='btn btn-primary btn-col'>Edit Inventory</button> */}
                </>
              ) : null}
            </div>
          );
        })
      ) : (
        <div
          style={{
            color: "red",
            fontSize: "25px",
            height: "200px",
            padding: "40px",
          }}
        >
          {!loading ? <h4>No Disease to show!!</h4> : ""}
        </div>
      )
    ) : null;
  const handleBack = (e) => {
    navigate(-1);
  };

  return (
    <>
      {/* <BackofficeNavBar/> */}
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "",
          }),
        }}
      >
        <div className="container displaylist-container ">
          {/* <button style={{padding:"5%",marginTop:"5%"}}
          className="btn btn-info topleft-single-btn"
          onClick={handleBack}
        >
          {" "}
          <i className="fas fa-chevron-left back-btn-icon"></i>Back
        </button> */}
          <div className="header-display">
            <h2 className="title-of-page">Comorbidites List</h2>

            <div className="search-section">
              <div className="search-div">
                <div style={{ display: "flex",marginLeft: "28%" }}>
                  <input
                    className="form-control search-input"
                    type="text"
                    placeholder="Search Here"
                    value={searchTerm}
                    maxLength="30"
                    onChange={handleSearchChange}
                  />
                  <button className="btn btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                  </button>
                  <div className="buttons-wrapper">
                    {category === 2 ? (
                      <button
                        style={{ marginLeft: "60%" }}
                        className="btn btn-primary btn-col"
                        onClick={() => setAddRentalShow(true)}
                      >
                        Add Comorbidites <i class="fas fa-plus"></i>
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="test-reports-display-list">
            <div className="feature-container">{dataDisplay}</div>
          </div>
          {totalPages > 1 ? (
            <div className="pagn pagn-small">
              <br />
              <br />
              <br />
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={perPage}
                totalItemsCount={total}
                pageRangeDisplayed={totalPages}
                onChange={handlePageChange}
                disabledClass="disabled-class"
              />
            </div>
          ) : null}

          {editShow ? (
            <EditPopUp show={editShow} onHide={() => setEditShow(false)} />
          ) : null}

          {deleteShow ? (
            <DeleteConfirmPopup
              show={deleteShow}
              onHide={() => {
                setDeleteShow(false);
                setDeleteIndex("");
              }}
            />
          ) : (
            ""
          )}

          {addRentalShow ? (
            <AddRentalPopUp
              show={addRentalShow}
              onHide={() => setAddRentalShow(false)}
            />
          ) : null}
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Comorbidites;
