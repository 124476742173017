import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from 'react-alert'
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Modal, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';

function AddRemark(props) {
  const login_datas = (
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const [called, setCalled] = useState(false)
  const [remarkData, setRemarkData] = useState('')
  const [nurseRemarks, setNurseRemarks] = useState([])
  const [docRemarks, setDocRemarks] = useState([])
  const [pharmRemarks, setPharmRemarks] = useState([])
  const [selected, setSelected] = useState(login_datas.user_type === "hospital_doctor" ? "doctor" : "pharm")
  const alert = useAlert()
  let id = props.trackId

  const fetchRemarks = () => {
    const token = sessionStorage.getItem("usertoken").replace(/["]+/g, "");
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status === "success") {
          setDocRemarks(res.data.doctor_remarks);
          setPharmRemarks(res.data.pharmacist_remarks);
          setNurseRemarks(res.data.nurse_remarks);
          setCalled(true)
        }
      })
      .catch((err) => {
        console.error("Failed to fetch remarks:", err);
      });
  };

  useEffect(() => {
    if (!called) {
      fetchRemarks();
    }
  }, []);

  const callSave = () => {
    const tokenString = sessionStorage.getItem('usertoken')
    let str = tokenString.replace(/["]+/g, '');
    let remarks = remarkData
    const dataToUpdate = { remarks }
    let url = login_datas.user_type === "hospital_doctor" ? `/api/v1/doctor/patient-prescriptions-detail/${id}/`
      : `/api/v1/doctor/pharmacy-fulfillment/${id}/`
    axios.put(`${APIURL}${url}`, dataToUpdate, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
      .then((res) => {
        if (res.data.status === "success") {
          setRemarkData("")
          alert.success("Remarks added !!")
          fetchRemarks();


        } else {
          alert.success("Error occured !!")
        }
      })
      .catch((err) => {
      });
  }

  const generateIcon = (name, type) => {
    const initials = name
      .split(" ")
      .slice(type === "doctor" ? 1 : 0, 2)
      .map((word) => word[0].toUpperCase())
      .join("");

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
        <circle cx="22.5" cy="22.5" r="17.5" stroke="rgb(60, 104, 158)" strokeWidth="1" fill="white" />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="14"
          fontWeight="bold"
          fill="black"
        >
          {initials}
        </text>
      </svg>
    );
  };

  const rmkDiv = (
    <div className="patientRecbutton1"
      style={{
        width: '100%',
        display: 'flex',
        borderRadius: "10px",
        border: "1.124px solid rgba(88, 182, 222, 0.15)",
        background: "#F0F9FD",
        fontSize: "12px",
        height: '49px',
        marginBottom: '3px'
      }}
    >
      <div className=" col-2">
        {login_datas.user_type === "hospital_doctor" ? generateIcon(login_datas.name, "doctor") : generateIcon(login_datas.name)}
      </div>
      <input
        type="text"
        className=" col-8"
        value={remarkData}
        name="remarks"
        placeholder='Add your remark here'
        style={{
          borderLeft: "none",
          borderRight: "none",
          borderTop: "none",
          borderBottom: "1px solid black",
        }}
        onChange={(e) => { setRemarkData(e.target.value) }}
        autoComplete="off"
      />
      <button disabled={remarkData === ''}
        style={{
          backgroundColor: "rgb(60, 104, 158)",
          borderRadius: '5px',
          color: "white",
        }}
        onClick={callSave} className=' btn-primary col-2'>Save</button>
    </div>
  )

  const DocRmkDisplay = <>
    {login_datas.user_type === "hospital_doctor" && !props.from && rmkDiv}
    {docRemarks && docRemarks.length > 0 ?
      docRemarks !== null && docRemarks.length > 0 &&
      <div className='rem-list' style={{ height: (login_datas.user_type !== "hospital_doctor" || props.from) ? '252px' : '200px' }} >
        <div style={{ textAlign: "left" }}>
          {docRemarks.map((item) => {
            return (
              <div style={{ display: 'flex', textAlign: "left", backgroundColor: "aliceblue", margin: '2px 2px 2px 0', padding: '2%' }}>
                <h6 style={{ color: "rgb(0, 101, 124)", fontSize: "14px", margin: 0, textTransform: "capitalize", padding: 0 }} className='col-5'>{item.doctor_name}<br />
                  <small className='' style={{ color: "CaptionText" }}>{item.date.slice(0, 10)}</small>
                </h6>
                <p className='break-word col-7' style={{ padding: '0 0 0 5px' }}>{item.remark}</p>
              </div>
            )
          })}
        </div>
      </div> : <div className="nodata rem-list" style={{ height: (login_datas.user_type !== "hospital_doctor" || props.from) ? '252px' : '200px' }}>No remarks added!!</div>
    }
  </>

  const PharmRmkDisplay = <>
    {login_datas.user_type === "hospital_pharmacist" && !props.from && rmkDiv}
    {pharmRemarks && pharmRemarks.length > 0 ?
      pharmRemarks !== null && pharmRemarks.length > 0 &&
      <div className='rem-list' style={{ height: (login_datas.user_type !== "hospital_pharmacist" || props.from) ? '252px' : '200px' }}>
        <div style={{ textAlign: "left", }}>
          {pharmRemarks.map((item) => {
            return (
              <div style={{ display: 'flex', textAlign: "left", backgroundColor: "aliceblue", margin: '2px 2px 2px 0', padding: '2%' }}>
                <h6 style={{ color: "rgb(0, 101, 124)", fontSize: "14px", margin: 0, textTransform: "capitalize", padding: 0 }} className='col-5'>{item.pharmacist_name}<br />
                  <small className='' style={{ color: "CaptionText" }}>{item.date.slice(0, 10)}</small>
                </h6>
                :<p className='col-7 break-word' style={{ padding: '0 0 0 5px' }}>{item.remark}</p>
              </div>
            )
          })}
        </div>
      </div> : <div className="nodata rem-list" style={{ height: (login_datas.user_type !== "hospital_pharmacist" || props.from) ? '252px' : '200px' }} >No remarks added!!</div>
    }
  </>

  const NurseRmkDisplay = <>
    {nurseRemarks && nurseRemarks.length > 0 &&

      nurseRemarks !== null && nurseRemarks.length > 0 ?
      <div className='rem-list' style={{ height: '252px' }} >
        <div style={{ textAlign: "left" }}>
          {nurseRemarks.map((item) => {
            return (item.length > 0 && item.map((i) => {
              return (
                <div style={{ display: 'flex', textAlign: "left", backgroundColor: "aliceblue", margin: '2px 2px 2px 0', padding: '2%' }}>
                  <h6 style={{ color: "rgb(0, 101, 124)", fontSize: "14px", margin: 0, textTransform: "capitalize", padding: 0 }} className='col-5'>{i.nurse_name}<br />
                    <small className='' style={{ color: "CaptionText" }}>{i.date}-{i.time}</small>
                  </h6>
                  <p className='break-word col-7' style={{ padding: '0 0 0 5px' }}>{i.remark}</p>
                </div>
              )
            })
            )
          })}
        </div>
      </div> : <div className="nodata rem-list" style={{ height: '252px' }}>No remarks added!!</div>
    }
  </>

  return (
    <div>
      <Modal show={true} centered size="md" onHide={props.close}>
        <ModalHeader closeButton>
          <ModalTitle style={{ fontSize: '18px', margin: 0 }}><b>{props.head}</b></ModalTitle>
        </ModalHeader>
        <Modal.Body style={{ textAlign: "left", display: 'flex', flexDirection: 'column' }} >
          <div style={{ display: 'flex', marginBottom: '1.5%' }}>
            <div className={login_datas.user_type === "hospital_doctor" ? (selected === "doctor" ? "selectedoption" : ' selectoptions') : (selected === "doctor" ? "selectedoption" : 'selectoptions')} onClick={() => { setSelected("doctor") }} >
              Doctor
            </div>
            <div className={login_datas.user_type === "hospital_doctor" ? (selected === "pharm" ? "selectedoption" : 'selectoptions') : (selected === "pharm" ? "selectedoption" : 'selectoptions')} onClick={() => { setSelected("pharm") }} >
              Pharmacist
            </div>
            {login_datas.user_type === "hospital_doctor" && <div className={selected === "nurse" ? "selectedoption" : 'selectoptions'} onClick={() => { setSelected("nurse") }} >
              Nurse
            </div>}
          </div>
          <>
            <div>
              {selected === "doctor" ? DocRmkDisplay : selected === "pharm" ? PharmRmkDisplay : NurseRmkDisplay}
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddRemark
