import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import bed from "../../../assets/images/icons/bed.png";
import ward from "../../../assets/images/icons/ward.png";
import { APIURL, TZ } from "../../../Global";
import { useAlert } from "react-alert";
import axios from "axios";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { NavDropdown } from "react-bootstrap";

function BedDetails(props) {
  console.log(props, "kkkdfdsfsd")
  const navigate = useNavigate();
  const alert = useAlert();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [patientDetails1, setPatientDetails1] = useState([]);
  const [DocDetails1, setDocDetails1] = useState([]);
  const [DutyDocDetails1, setDutyDocDetails1] = useState([]);
  const [NurseDetails1, setNurseDetails1] = useState([]);
  const [CarerDetails1, setCarerDetails1] = useState([]);
  const [patientDetails, setPatientDetails] = useState({
    id: (location.state && location.state.id) ? location.state.id : props.propsvar.from === "admission" ? props.propsvar.assign.id : "",
    name: location.state ? location.state.name : props.propsvar.from === "admission" ? props.propsvar.assign.name : "",
    gender: location.state ? location.state.gender : "",
    age: location.state ? location.state.age : "",
    mob: location.state ? location.state.mob : props.propsvar.from === "admission" ? props.propsvar.assign.mobile_number : "",
  });
  const [DocDetails, setDocDetails] = useState({});
  const [DutyDocDetails, setDutyDocDetails] = useState([]);
  const [NurseDetails, setNurseDetails] = useState([]);
  const [CarerDetails, setCarerDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPat, setSearchPat] = useState(false);
  const [searchDoc, setSearchDoc] = useState(false);
  const [searchDDoc, setSearchDDoc] = useState(false);
  const [searchNur, setSearchNur] = useState(false);
  const [searchCare, setSearchCare] = useState(false);
  const [displayPat, setDisplayPat] = useState(false);
  const [patient, setPatient] = useState([]);
  useEffect(() => {
    if (props.bedUnitCode !== "") {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");

      axios
        .get(
          `${APIURL}/api/v1/service-provider/assign-room/?bedunit_code=${props.bedUnitCode}`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setDocDetails1(res.data.data.chief_doctor);
            setPatientDetails1(res.data.data.patient);
            setDutyDocDetails1(res.data.data.duty_doctor);
            setCarerDetails1(res.data.data.carer);
            setNurseDetails1(res.data.data.nurse);
          }
        });
    }
  }, [props.bedUnitCode, refresh]);
  const handleDelete = (e, id) => {

    e.preventDefault();
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const dataToSend = {
      duty_doctor: id,
      bedunit_code: props.bedUnitCode
    }
    axios
      .put(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert.success(res.data.message)

          setRefresh(!refresh)
        }
      });
  }
  const handleDelete1 = (e, id) => {


    e.preventDefault();
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const dataToSend = {
      duty_nurse: id,
      bedunit_code: props.bedUnitCode
    }
    axios
      .put(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert.success(res.data.message)

          setRefresh(!refresh)
        }
      });
  }
  const initialRender = useRef(true);
  useEffect(() => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setPatient([]);
    } else {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
      let url = "";
      if (searchPat) {
        url =
          "/api/v1/staff/patient-list/?timeZone=" + currentTimezone + "&per_page=1000&page=1&op=true&q=" + searchTerm;
      } else if (searchDoc || searchDDoc) {
        url = "/api/v1/connect/doctorslist/?timeZone=" + currentTimezone + "&q=" + searchTerm;
      } else if (searchNur) {
        url = "/api/v1/service-provider/nurse/?timeZone=" + currentTimezone + "&assignee&search=" + searchTerm;
      } else {
        url = "/api/v1/service-provider/carers/?timeZone=" + currentTimezone + "&assignee&search=" + searchTerm;
      }

      axios
        .get(`${APIURL}${url}`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (searchPat) {
              setPatient(res.data.patient);
            } else if (searchDoc || searchDDoc) {
              setPatient(res.data.results);
            }

            // setServiceShow(false)
            setDisplayPat(true);
          } else if (res.status === 200) {
            setPatient(res.data);
            setDisplayPat(true);
          }
        })
        .catch((error) => { });
    }
  }, [searchTerm]);
  const handleDelete2 = (e, id) => {

    e.preventDefault();
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const dataToSend = {
      duty_carer: id,
      bedunit_code: props.bedUnitCode
    }
    axios
      .put(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert.success(res.data.message)
          setRefresh(!refresh)
        }
      });
  }
  const handleSearchChangeMsg = (e) => {
    setSearchTerm(e.target.value);
    setDisplayPat(false);
  };
  const Submit = () => {
    if (props.bedUnitCode === "") {
      alert.error("Select a bed");
    } else if (
      (patientDetails.id !== "" && patientDetails1.length === 0) ||
      DocDetails.id !== "" ||
      NurseDetails.length > 0 ||
      DutyDocDetails.length > 0 ||
      CarerDetails.length > 0
    ) {
      const tokenString = sessionStorage.getItem("usertoken");
      const v = tokenString;
      let str = v.replace(/["]+/g, "");
      let dataToSend = {
        bedunit_code: props.bedUnitCode,
        bedspace_type: props.bedSpace,
      };

      if (patientDetails1.length === 0) {
        if (patientDetails.id) dataToSend["patient"] = patientDetails.id;
      }
      if (DocDetails.id) dataToSend["chief_doctor"] = DocDetails.id;

      if (DutyDocDetails.length > 0) {
        let arr = [];
        DutyDocDetails.map((item) => {
          arr.push(item.id);
        });
        dataToSend["duty_doctor"] = arr;
      }
      if (NurseDetails.length > 0) {
        let arr = [];
        NurseDetails.map((item) => {
          arr.push(item.id);
        });
        dataToSend["duty_nurse"] = arr;
      }
      if (CarerDetails.length > 0) {
        let arr = [];
        CarerDetails.map((item) => {
          arr.push(item.id);
        });
        dataToSend["duty_carer"] = arr;
      }

      axios
        .post(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenString.replace(/["]+/g, ""),
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            alert.success("Successfully Assigned");
            if (props.propsvar.from) {
              setTimeout(async () => {
                props.propsvar.onSuccess(props.bedUnitCode)
              }, 3000);
            }
            setCarerDetails([]);
            setDocDetails({ name: "", id: "" });
            setNurseDetails([]);
            setDutyDocDetails([]);
            setPatientDetails({ name: "", id: "" });


            setRefresh(!refresh);
            props.onSuccess()

          }
          else {
            alert.error(res.data.message)
          }
        });
    }
    else { alert.error("No one is selected") }
  };
  const handleClickSearch = (item) => {
    initialRender.current = true;
    setSearchTerm(item.full_name);
    setSearchTerm("");
    setDisplayPat(false);
    setSearchPat(false);
    if (searchPat) {
      setPatientDetails({ id: item.id, name: item.full_name });
    } else if (searchDoc) {
      setDocDetails({ id: item.id, name: item.full_name });
    } else if (searchDDoc) {
      let arr = [];
      DutyDocDetails.map((item) => {
        arr.push(item.id);
      });
      if (!arr.includes(item.id) && !DutyDocDetails1.includes(item.full_name)) {
        setDutyDocDetails([
          ...DutyDocDetails,
          { id: item.id, name: item.full_name },
        ]);
      }
    } else if (searchNur) {
      let arr = [];
      NurseDetails.map((item) => {
        arr.push(item.id);
      });
      if (!arr.includes(item.user) && !NurseDetails1.includes(item.name)) {
        setNurseDetails([...NurseDetails, { id: item.user, name: item.name }]);
      }
    } else {
      let arr = [];
      CarerDetails.map((item) => {
        arr.push(item.id);
      });
      if (!arr.includes(item.user) && !CarerDetails1.includes(item.name)) {
        setCarerDetails([...CarerDetails, { id: item.user, name: item.name }]);
      }
    }
  };
  const Search = (term) => {

    return (
      <>
        <div className="flex-row1">
          <div className="search-form" style={{ width: "100%" }}>
            <input
              className="search-input"
              type="text"
              maxLength="100"
              style={{ width: "100%" }}
              placeholder={"Search " + term}
              value={searchTerm}
              onChange={handleSearchChangeMsg}
            />

            <button
              // onClick={handleSearch}
              className="btn btn-primary search-icon"
            >
              {" "}
              <i className="fa fa-search"></i>{" "}
            </button>
          </div>
        </div>
        {displayPat ? (
          <div
            className="suggest-display adjust-dimensions"
            style={{ width: "87%" }}
          >
            {patient.length > 0 ? (
              patient.map((item) => {
                return (
                  <p
                    onClick={() => handleClickSearch(item)}
                    className="suggest-item text-secondary"
                  >
                    {searchNur || searchCare ? item.name : item.full_name}
                  </p>
                );
              })
            ) : (
              <h6 className="text-muted">No User found !!</h6>
            )}
          </div>
        ) : null}
      </>
    );
  };
  const Update = () => {
    if (props.bedUnitCode === "") {
      alert.error("Select a bed");
    }
    else if (props.assigned) {
      alert.error("Already occupied")
    }
    else if (
      (patientDetails.id !== "")

    ) {
      const tokenString = sessionStorage.getItem("usertoken");
      const v = tokenString;
      let str = v.replace(/["]+/g, "");
      let dataToSend = {
        bedunit_code: props.bedUnitCode,
        bedspace_type: props.bedSpace,
        patient: patientDetails.id
      };


      axios
        .put(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenString.replace(/["]+/g, ""),
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (props.from) {
              alert.success("Successfully reassigned");

              setTimeout(async () => {
                props.onSuccess(props.bedUnitCode)
              }, 2000);

            }
            else {
              alert.success("Successfully reassigned");
              navigate(`/patient-record/id/${patientDetails.id}/mob/${patientDetails.mob}`)

            }

          }
          else {
            alert.error(res.data.message)
          }
        });
    }

  };
  return (
    <div>

      {!props.propsvar.from ?  <div className="">

        <SimpleBar style={{ maxHeight: "965px" }}>
          <div className="displayrooms" style={{
            marginTop: "15px",
            padding: "auto",
            paddingBottom: "1%",
          }}>
            {patientDetails.id ||
              DocDetails.id ||
              NurseDetails.length > 0 ||
              DutyDocDetails.length > 0 ||
              CarerDetails.length > 0
              ? props.bedUnitCode !== "" && (
                <button
                  style={{
                    width: "95%",
                    padding: "1%",
                    marginLeft: "3%",
                    flexShrink: "0",
                    borderRadius: "10px",
                    marginTop: "2%",
                    border: "1px solid #A0A0A0",
                    background: "#098D85",
                    color: "white",
                  }}
                  onClick={() => { (props.propsvar.reassign && props.propsvar.reassign === true) || (location.state && location.state.reassign) ? Update() : Submit() }}
                >
                  {" "}
                  {(props.propsvar.reassign && props.propsvar.reassign === true) || (location.state && location.state.reassign) ? "Reassign" : "Save"}&nbsp;
                  {" "}


                </button>
              )
              : ""}
            <div className="patientbox">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h6
                  style={{
                    color: "#000",
                    fontFamily: "Poppins",

                    marginTop: "10px",
                    marginLeft: "5px",
                  }}
                >
                  Patient
                </h6>
                {patientDetails.id === "" &&
                  patientDetails1.length === 0 ? (
                  <button
                    onClick={() => {
                      setSearchPat(!searchPat);

                      setSearchCare(false);
                      setSearchNur(false);
                      setSearchDoc(false);

                      setSearchDDoc(false);
                      setSearchTerm("");
                      setPatient([]);
                    }}
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      marginRight: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {!searchPat ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                          fill="black"
                        />
                      </svg>
                    ) : (
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    )}{" "}
                  </button>
                ) : (
                  ""
                )}
              </div>
              {searchPat && Search("patient")}

              {patientDetails1.length > 0 ? (
                <div className="patientdisplay">
                  <h6 style={{ marginTop: "1%" }} className="row">
                    <svg className="col-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                    >
                      <circle
                        cx="17.5"
                        cy="17.5"
                        r="17.5"
                        fill="#B5CA34"
                      />
                      <text
                        x="50%"
                        y="50%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fontSize="14"
                        fontWeight="bold"
                        fill="black"
                      >
                        {patientDetails1[0].name
                          .split(" ").slice(0, 2)
                          .map((word) => word[0])
                          .join("")
                          .toUpperCase()}
                      </text>
                    </svg>
                    <span style={{ textTransform: "capitalize" }} className="col-10">
                      {patientDetails1[0].name} <br />
                      <small
                        style={{

                          fontSize: "15px",
                          marginTop: "-2px",
                        }}
                      >
                        {" "}
                        {patientDetails1[0].gender} {patientDetails1[0].age}{" "}
                      </small></span>
                  </h6>
                </div>
              ) : patientDetails.length > 0 || patientDetails.name ? (
                <>
                  <div className="patientdisplay-tosave">
                    <h6 style={{ marginTop: "1%" }} className="row">
                      <svg
                        className="col-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                      >
                        <circle
                          cx="17.5"
                          cy="17.5"
                          r="17.5"
                          fill="#B5CA34"
                        />
                        <text
                          x="50%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fontSize="14"
                          fontWeight="bold"
                          fill="black"
                        >
                          {patientDetails &&
                            patientDetails.name
                              .split(" ").slice(0, 2)
                              .map((word) => word[0])
                              .join("")
                              .toUpperCase()}
                        </text>
                      </svg>
                      <span className="col-10" style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>{patientDetails.name}
                        {/* <h6
                              style={{
                               
                                fontSize: "15px",
                                marginTop: "-2px",
                              }}
                            >
                              {" "}
                              {patientDetails.gender} {patientDetails.age}{" "}
                            </h6> */}

                        {!props.propsvar.from && <button
                          onClick={() => {
                            setPatientDetails({ id: "", name: "" });
                          }}
                          style={{
                            backgroundColor: "#F0F9FD",
                            width: "24px",
                            height: "24px",
                            color: "#f8d772",
                            flexShrink: "0",
                            marginLeft: "auto",
                          }}
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>{" "}
                        </button>}
                      </span>
                    </h6>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            
              <div className="patientbox">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h6
                    style={{
                      color: "#000",
                      fontFamily: "Poppins",

                      marginTop: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    Chief Doctor
                  </h6>
                  {DocDetails1.length === 0 && (
                    <button
                      onClick={() => {
                        setSearchDoc(!searchDoc);
                        setSearchCare(false);
                        setSearchNur(false);

                        setSearchPat(false);
                        setSearchDDoc(false);
                        setSearchTerm("");
                        setPatient([]);
                      }}
                      style={{
                        width: "24px",
                        height: "24px",
                        flexShrink: "0",
                        marginRight: "20px",
                        marginTop: "10px",
                      }}
                    >
                      {!searchDoc ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                            fill="black"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                            fill="black"
                          />
                        </svg>
                      ) : (
                        <i class="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </button>
                  )}
                </div>
                {searchDoc && Search("doctor")}
                {DocDetails1.length > 0 ? (
                  <div className="patientdisplay">
                    <h6 style={{ marginTop: "1%" }} className="row">
                      <svg className="col-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                      >
                        <circle
                          cx="17.5"
                          cy="17.5"
                          r="17.5"
                          fill="#B5CA34"
                        />
                        <text
                          x="50%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fontSize="14"
                          fontWeight="bold"
                          fill="black"
                        >
                          {DocDetails1[0].name
                            .split(" ").slice(1, 3)
                            .map((word) => word[0])
                            .join("")
                            .toUpperCase()}
                        </text>
                      </svg>
                      <span style={{ textTransform: "capitalize" }} className="col-10">{DocDetails1[0].name} {" "}<br />
                        <small style={{ textTransform: "capitalize" }}>{DocDetails1[0].specialization}</small></span>
                    </h6>
                  </div>
                ) : DocDetails.length > 0 || DocDetails.name ? (
                  <>
                    <div className="patientdisplay-tosave">
                      <h6 style={{ marginTop: "1%" }} className="row">
                        <svg className="col-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                        >
                          <circle
                            cx="17.5"
                            cy="17.5"
                            r="17.5"
                            fill="#B5CA34"
                          />
                          <text
                            x="50%"
                            y="50%"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            fontSize="14"
                            fontWeight="bold"
                            fill="black"
                          >
                            {DocDetails.name
                              .split(" ").slice(1, 3)
                              .map((word) => word[0])
                              .join("")
                              .toUpperCase()}
                          </text>
                        </svg>
                        <span className="col-10" style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>{DocDetails.name}
                          <button
                            onClick={() => {
                              setDocDetails({ id: "", name: "" });
                            }}
                            style={{
                              backgroundColor: "#F0F9FD",
                              width: "24px",
                              height: "24px",
                              color: "#f8d772",
                              flexShrink: "0",
                              marginLeft: "auto",
                            }}
                          >
                            <i class="fa fa-times" aria-hidden="true"></i>{" "}
                          </button></span>
                      </h6>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="patientbox">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h6
                    style={{
                      color: "#000",
                      fontFamily: "Poppins",

                      marginTop: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    Duty Doctors
                  </h6>
                  <button
                    onClick={() => {
                      setSearchDDoc(!searchDDoc);

                      setSearchCare(false);
                      setSearchNur(false);
                      setSearchDoc(false);
                      setSearchPat(false);
                      setSearchTerm("");
                      setPatient([]);
                    }}
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      marginRight: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {!searchDDoc ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                          fill="black"
                        />
                      </svg>
                    ) : (
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    )}{" "}
                  </button>
                </div>
                {searchDDoc && Search("Duty Doctor")}
                {DutyDocDetails1.length > 0 && (
                  <SimpleBar style={{ maxHeight: "200px" }}>
                    {DutyDocDetails1.map((item) => {
                      return (
                        <div className="patientdisplay">
                          <h6 style={{ marginTop: "1%" }} className="row">
                            <svg className="col-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              viewBox="0 0 35 35"
                              fill="none"
                            >
                              <circle
                                cx="17.5"
                                cy="17.5"
                                r="17.5"
                                fill="#B5CA34"
                              />
                              <text
                                x="50%"
                                y="50%"
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontSize="14"
                                fontWeight="bold"
                                fill="black"
                              >
                                {item.name
                                  .split(" ").slice(1, 3)
                                  .map((word) => word[0])
                                  .join("")
                                  .toUpperCase()}
                              </text>
                            </svg>
                            <span className="col-10" style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>{item.name}
                              <button

                                style={{
                                  backgroundColor: "#F0F9FD",
                                  width: "24px",
                                  height: "24px",
                                  color: "red",
                                  flexShrink: "0",
                                  marginLeft: "auto",
                                }}
                              >
                                <i
                                  class="fa fa-times"
                                  aria-hidden="true" onClick={(e) => handleDelete(e, item.id)}
                                > </i>{" "}
                              </button>
                            </span>
                          </h6>
                        </div>
                      );
                    })}
                  </SimpleBar>
                )}
                <SimpleBar style={{ maxHeight: "200px" }}>
                  {DutyDocDetails.length > 0 &&
                    DutyDocDetails.map((item) => {
                      return (
                        item.id !== "" && (
                          <div className="patientdisplay-tosave">
                            <h6
                              style={{ marginTop: "1%" }} className="row"
                            >
                              <svg className="col-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                              >
                                <circle
                                  cx="17.5"
                                  cy="17.5"
                                  r="17.5"
                                  fill="#B5CA34"
                                />
                                <text
                                  x="50%"
                                  y="50%"
                                  dominantBaseline="middle"
                                  textAnchor="middle"
                                  fontSize="14"
                                  fontWeight="bold"
                                  fill="black"
                                >
                                  {item.name
                                    ? item.name
                                      .split(" ").slice(1, 3)
                                      .map((word) => word[0])
                                      .join("")
                                      .toUpperCase()
                                    : ""}
                                </text>
                              </svg>
                              <span className="col-10" style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>{item.name}
                                <button
                                  onClick={() => {
                                    setDutyDocDetails((current) =>
                                      current.filter((i) => i.id !== item.id)
                                    );
                                  }}
                                  style={{
                                    backgroundColor: "#F0F9FD",
                                    width: "24px",
                                    height: "24px",
                                    color: "#f8d772",
                                    flexShrink: "0",
                                    marginLeft: "auto",
                                  }}
                                >
                                  <i
                                    class="fa fa-times"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </button>
                              </span>
                            </h6>
                          </div>
                        )
                      );
                    })}
                </SimpleBar>
              </div>

              <div className="patientbox">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h6
                    style={{
                      color: "#000",
                      fontFamily: "Poppins",

                      marginTop: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    Duty Nurse
                  </h6>
                  <button
                    onClick={() => {
                      setSearchNur(!searchNur);
                      setSearchCare(false);

                      setSearchDoc(false);
                      setSearchPat(false);
                      setSearchDDoc(false);
                      setSearchTerm("");
                      setPatient([]);
                    }}
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      marginRight: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {!searchNur ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                          fill="black"
                        />
                      </svg>
                    ) : (
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    )}{" "}
                  </button>
                </div>
                {searchNur && Search("Nurse")}
                {NurseDetails1.length > 0 && (
                  <SimpleBar style={{ maxHeight: "200px" }}>
                    {NurseDetails1.map((item) => {
                      return (
                        <div className="patientdisplay">
                          <h6 style={{ marginTop: "1%" }} className="row">
                            <svg className="col-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              viewBox="0 0 35 35"
                              fill="none"
                            >
                              <circle
                                cx="17.5"
                                cy="17.5"
                                r="17.5"
                                fill="#B5CA34"
                              />
                              <text
                                x="50%"
                                y="50%"
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontSize="14"
                                fontWeight="bold"
                                fill="black"
                              >
                                {item
                                  ? item.name
                                    .split(" ").slice(0, 2)
                                    .map((word) => word[0])
                                    .join("")
                                    .toUpperCase()
                                  : ""}
                              </text>
                            </svg>
                            <span className="col-10" style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>{item.name}   <button

                              style={{
                                backgroundColor: "#F0F9FD",
                                width: "24px",
                                height: "24px",
                                color: "red",
                                flexShrink: "0",
                                marginLeft: "auto",
                              }}
                            >
                              <i
                                class="fa fa-times"
                                aria-hidden="true" onClick={(e) => handleDelete1(e, item.id)}
                              > </i>{" "}
                            </button>
                            </span>
                          </h6>
                        </div>
                      );
                    })}
                  </SimpleBar>
                )}
                <SimpleBar style={{ maxHeight: "200px" }}>
                  {NurseDetails.length > 0 &&
                    NurseDetails.map((item) => {
                      return (
                        item.id !== "" && (
                          <div className="patientdisplay-tosave">
                            <h6
                              style={{ marginTop: "1%" }}
                              className="row"
                            >
                              <svg className="col-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                              >
                                <circle
                                  cx="17.5"
                                  cy="17.5"
                                  r="17.5"
                                  fill="#B5CA34"
                                />
                                <text
                                  x="50%"
                                  y="50%"
                                  dominantBaseline="middle"
                                  textAnchor="middle"
                                  fontSize="14"
                                  fontWeight="bold"
                                  fill="black"
                                >
                                  {item.name
                                    ? item.name
                                      .split(" ").slice(0, 2)
                                      .map((word) => word[0])
                                      .join("")
                                      .toUpperCase()
                                    : ""}
                                </text>
                              </svg>
                              <span className="col-10" style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>{item.name}
                                <button
                                  onClick={() => {
                                    setNurseDetails((current) =>
                                      current.filter((i) => i.id !== item.id)
                                    );
                                  }}
                                  style={{
                                    backgroundColor: "#F0F9FD",
                                    width: "24px",
                                    height: "24px",
                                    color: "#f8d772",
                                    flexShrink: "0",
                                    marginLeft: "auto",
                                  }}
                                >
                                  <i
                                    class="fa fa-times"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </button>
                              </span>
                            </h6>
                          </div>
                        )
                      );
                    })}
                </SimpleBar>
              </div>
              <div className="patientbox">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h6
                    style={{
                      color: "#000",
                      fontFamily: "Poppins",

                      marginTop: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    Carer
                  </h6>
                  <button
                    onClick={() => {
                      setSearchCare(!searchCare);

                      setSearchNur(false);
                      setSearchDoc(false);
                      setSearchPat(false);
                      setSearchDDoc(false);
                      setSearchTerm("");
                      setPatient([]);
                    }}
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: "0",
                      marginRight: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {!searchCare ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                          fill="black"
                        />
                      </svg>
                    ) : (
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    )}{" "}
                  </button>
                </div>
                {searchCare && Search("Carer")}
                {CarerDetails1.length > 0 && (
                  <SimpleBar style={{ maxHeight: "200px" }}>
                    {CarerDetails1.length > 0 &&
                      CarerDetails1.map((item) => {
                        return (
                          <div className="patientdisplay">
                            <h6
                              style={{ marginTop: "1%" }}
                              className="row"
                            >
                              <svg
                                className="col-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                              >
                                <circle
                                  cx="17.5"
                                  cy="17.5"
                                  r="17.5"
                                  fill="#B5CA34"
                                />
                                <text
                                  x="50%"
                                  y="50%"
                                  dominantBaseline="middle"
                                  textAnchor="middle"
                                  fontSize="14"
                                  fontWeight="bold"
                                  fill="black"
                                >
                                  {item
                                    ? item.name
                                      .split(" ").slice(0, 2)
                                      .map((word) => word[0])
                                      .join("")
                                      .toUpperCase()
                                    : ""}
                                </text>
                              </svg>
                              <span className="col-10" style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>{item.name}   <button

                                style={{
                                  backgroundColor: "#F0F9FD",
                                  width: "24px",
                                  height: "24px",
                                  color: "red",
                                  flexShrink: "0",
                                  marginLeft: "auto",
                                }}
                              >
                                <i
                                  class="fa fa-times"
                                  aria-hidden="true" onClick={(e) => handleDelete2(e, item.id)}
                                > </i>{" "}
                              </button>
                              </span>
                            </h6>
                          </div>
                        );
                      })}
                  </SimpleBar>
                )}
                <SimpleBar style={{ maxHeight: "200px" }}>
                  {CarerDetails.length > 0 &&
                    CarerDetails.map((item) => {
                      return (
                        item.id !== "" && (
                          <div className="patientdisplay-tosave">
                            <h6
                              style={{ marginTop: "1%" }} className="row"
                            >
                              <svg className="col-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                              >
                                <circle
                                  cx="17.5"
                                  cy="17.5"
                                  r="17.5"
                                  fill="#B5CA34"
                                />
                                <text
                                  x="50%"
                                  y="50%"
                                  dominantBaseline="middle"
                                  textAnchor="middle"
                                  fontSize="14"
                                  fontWeight="bold"
                                  fill="black"
                                >
                                  {item.name
                                    ? item.name
                                      .split(" ").slice(0, 2)
                                      .map((word) => word[0])
                                      .join("")
                                      .toUpperCase()
                                    : ""}
                                </text>
                              </svg>
                              <span className="col-10" style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>{item.name}
                                <button
                                  onClick={() => {
                                    setCarerDetails((current) =>
                                      current.filter((i) => i.id !== item.id)
                                    );
                                  }}
                                  style={{
                                    backgroundColor: "#F0F9FD",
                                    width: "24px",
                                    height: "24px",
                                    color: "#f8d772",
                                    flexShrink: "0",
                                    marginLeft: "auto",
                                  }}
                                >
                                  <i
                                    class="fa fa-times"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </button>
                              </span>
                            </h6>
                          </div>
                        )
                      );
                    })}
                </SimpleBar>
              </div>
          </div>
        </SimpleBar>

      </div>:
      
    props.bedUnitCode !== "" && (
          <button
            style={{
              width: "95%",
              padding: "1%",
              marginLeft: "3%",
              flexShrink: "0",
              borderRadius: "10px",
              marginTop: "2%",
              border: "1px solid #A0A0A0",
              background: "#098D85",
              color: "white",
            }}
            onClick={() => { (props.propsvar.reassign && props.propsvar.reassign === true) || (location.state && location.state.reassign) ? Update() : Submit() }}
          >
            {" "}
            {(props.propsvar.reassign && props.propsvar.reassign === true) || (location.state && location.state.reassign) ? "Reassign" : "Save"}&nbsp;
            {" "}


          </button>
         )
        
}
      
    </div>
  )
}
export default BedDetails;