import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../BDO/addbdo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../BDO/dob.css";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import { APIURL,TZ } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";
import { useAlert } from "react-alert";

function AddWard(props) {

    const [error, setError] = useState({});
    const alert = useAlert();
    const [warddata, setWardData] = useState({
        wardname: "",
        wardnumber: "",
        wardcount: { value: "", label: "" },
        wardprice: "",
        bedcount: "",
      });
      const wardType = [
        { value: "deluxe", label: "Deluxe" },
        { value: "semi-deluxe", label: "Semi-Delux" },
        { value: "executive", label: "Executive" },
        { value: "vip", label: "VIP" },
        { value: "vvip", label: "VVIP" },
        { value: "standard", label: "Standard" },
      ];
      const validate1 = () => {
        let input = warddata;
        let focussed = false;
        let errors = {};
        let isValid = true;
        setError({});
    
        if (!input["wardname"] || input["wardname"] === "") {
          isValid = false;
          errors["wardname"] = "Enter ward name";
        }
    
        if (!input["wardnumber"] || input["wardnumber"] === "") {
          isValid = false;
          errors["wardnumber"] = "Enter ward number";
        }
        if (/^0[0-9]+$/.test(input["wardnumber"]) || (input["wardnumber"]==="0")) {
          isValid = false;
          errors["wardnumber"] = "Ward number cannot start with zero";
           }
    
        if (
          typeof warddata.wardcount.value == "undefined" ||
          warddata.wardcount.value === "" ||
          warddata.wardcount.value === null
        ) {
          isValid = false;
          errors["wardcount"] = "Select ward type";
        }
    
        if (!input["wardprice"] || input["wardprice"] === "") {
          isValid = false;
          errors["wardprice"] = "Enter ward price";
        }
        if (!input["bedcount"] || input["bedcount"] === "") {
          isValid = false;
          errors["bedcount"] = "Enter bed count";
        }
    
        setError((currentstate) => ({
          ...currentstate,
          ...errors,
        }));
    
        return isValid;
      };
    const handleAddWard = (e) => {
    
        e.preventDefault();
        if (validate1()) {
          const tokenStr = sessionStorage.getItem("usertoken");
    
          if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
    
            const dataToSend = {
              ward_name: warddata.wardname,
              ward_number: warddata.wardnumber,
              bed_price: warddata.wardprice,
              bed_capacity: warddata.bedcount,
              building: props.building,
              floor: props.floor,
              ward_type: warddata.wardcount.value,
            };
            axios
              .post(`${APIURL}/api/v1/service-provider/add-wards/`, dataToSend, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Token " + v2,
                },
              })
              .then((res) => {
                if (res.data.status === "success") {
                  alert.success(res.data.message);
    
                 props.onSuccess();
                  setWardData({
                    wardname: "",
                    wardnumber: "",
                    wardcount: { value: "", label: "" },
                    wardprice: "",
                    bedcount: "",
                  });
                } else {
                  alert.error(res.data.message);
                }
              })
              .catch((err) => {});
          }
        }
      };
      const handleChange1 = (e) => {
        const { name, value } = e.target;
        if(name==="wardprice"&&value<100000000){
            setWardData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }
          else{
            setWardData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }
        
      };
    return(
<>
<label
              style={{
                color: "#212121",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "500",
                lineHeight: "21px",
              
              }}
            >
              Create Wards
            </label>
          <form className="roomsouter " onSubmit={handleAddWard}>
          
            <div className="row" style={{ justifyContent: "center" }} >
              <div className="col-3">
                <input
                  style={{
                     height: "40px",
                    flexShrink: "0",
                    width:"100%",
                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    background: "#FFF",
                    color: "#000",
                  }}
                  type="text"
                  placeholder="Ward Name"
                  name="wardname"
                  value={warddata.wardname}
                  onChange={handleChange1}
                />
                {error.wardname ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.wardname}
                  </div>
                ) : null}
              </div>
              <div className="col-2">
                <input
                  type="number"
                  min="1"
                  placeholder="Ward No"
                  name="wardnumber"
                  value={warddata.wardnumber}
                  onChange={handleChange1}
                  style={{
                     height: "40px",
                    flexShrink: "0",
                    width:"100%",
                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    color: "#000",
                    background: "#FFF",
                    // marginRight: "10px",
                  }}
                />
                {error.wardnumber ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.wardnumber}
                  </div>
                ) : null}
              </div>

              <div className="col-2">
                <input
                  type="number"
                  min="0"
                  placeholder="Bed Count"
                  name="bedcount"
                  value={warddata.bedcount}
                  onChange={handleChange1}
                  style={{
                     height: "40px",
                    width:"100%",
                    flexShrink: "0",
                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    color: "#000",
                    background: "#FFF",
                  }}
                />
                {error.bedcount ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.bedcount}
                  </div>
                ) : null}
              </div>
              <div className="col-2">
                <select
                  style={{
                    width: "100%",
                     height: "40px",
                    
                    flexShrink: "0",
                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    color: "#000",
                    background: "#FFF",
                  }}
                  value={warddata.wardcount.value} // Set the value property instead of the whole object
                  onChange={(e) =>  setWardData((current) => ({
                    ...current,
                    wardcount: {
                      value: e.target.value,
                      label: e.target.value, // You can set the label as the same value if desired
                    },
                  }))}// Pass the selected value instead of the whole event
                  name="wardcount"
                  className=" "
                >
                  <option value="">Select ward Type</option>{" "}
                  {/* Add a default option */}
                  {wardType.map((wardtype) => (
                    <option key={wardtype.value} value={wardtype.value}>
                      {wardtype.label}
                    </option>
                  ))}
                </select>
                {error.wardcount ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.wardcount}
                  </div>
                ) : null}
              </div>

              <div className="col-2">
                {" "}
                <input
                  type="number"
                  min="0"
                  placeholder="Bed Price"
                  name="wardprice"
                  value={warddata.wardprice}
                  onChange={handleChange1}
                  style={{
                     height: "40px",
                    flexShrink: "0",
                    width:"100%",
                    borderRadius: "10px",
                    color: "#000",
                    border: "1px solid #9A9A9A",
                    background: "#FFF",
                    // marginRight: "10px",
                  }}
                />
                {error.wardprice ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.wardprice}
                  </div>
                ) : null}
              </div>
              <button
                className="facilitybuttons col-1"
                style={{
                  paddingTop: "14px",
                  cursor: "pointer",
                   height: "40px",
                //   width:"10%",
                  // marginLeft: "8%",
                }}
              >
                Done
              </button>
            </div>
          </form>
          </>
    )

}
export default AddWard;