import React, { useState, useEffect, useRef } from 'react'

import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import "simplebar-react/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";
function Procedure(props) {
  const alert = useAlert();
  const navigate = useNavigate();
  const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
  const [scanRefresh, setScanRefresh] = useState(false)
  const [deleteScanConfirmModalShow, setDeleteScanConfirmModalShow] = useState(false);
  const [scanIndexToDelete, setScanIndexToDelete] = useState();
  const [procedureList, setProcedureList] = useState([
  ]);
  const delConfirmRef = useRef(null)
  useEffect(() => {
    const handleKey = (e) => {

      if (e.altKey && e.key === 's') {
        e.preventDefault()
        delConfirmRef.current.click()
      } else if (e.key === 'Enter') {
        e.preventDefault()
        delConfirmRef.current.click()
      } else if (e.altKey && e.key === 'c') {
        e.preventDefault()
        setDeleteScanConfirmModalShow(false)

      }

    }
    document.addEventListener("keydown", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
    }

  }, []);
  const DeleteScanConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="modal-popout"
      >
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
          <label style={{ margin: '20px auto', fontSize: '18px', color: '#4e4e4e' }}>Please confirm to delete scheduled procedure!</label>
          <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
            <button type="button" className='swirl-butns' style={{ margin: '10px 4px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
            <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 4px', backgroundColor: '#2b69a3' }} ref={delConfirmRef}
              onClick={() => {
                setDeleteScanConfirmModalShow(false);
                handleDeleteScan(scanIndexToDelete);
              }}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  useEffect(() => {
    setProcedureList([])

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/procedure-list/?patient_id=${props.id}&${TZ}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.message.length > 0) {
            const data = res.data.message;
            const filteredData = data.filter((item) => {
              return item.id !== "";
            });


            setProcedureList(filteredData);


          }
        }
      });

  }, [scanRefresh]);
  const handleDeleteScan = (index) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const ts_id = index
    axios
      .delete(`${APIURL}/api/v1/doctor/procedure-list-details/${ts_id}`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status == "success") {
          alert.success("successfully deleted")
          setScanRefresh(!scanRefresh)
        } else {

        }
      })

  };

  const deleteRowScan = (id) => {
    setDeleteScanConfirmModalShow("true");
    setScanIndexToDelete(id);
  };





  return (

    <div style={{ padding: "1%" }}>
      <div style={{ marginBottom: "4%", padding: "0.5%" }}>
        <button disabled={login_datas.user_type !== "hospital_doctor" && login_datas.user_type !== "hospital_allied_prof"} onClick={() => { navigate(`/treat-plan/${props.id}`, { state: { cat: "procedure" } }) }} style={{ margin: "0px", backgroundColor: "rgba(43, 147, 160, 1)", color: "white", width: "20%", float: "right" }} className='treat-button-main'><b>Manage Procedures</b></button>
      </div>
      <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
        {procedureList.length > 0 && <div className='row pro_lists ' style={{ backgroundColor: "rgba(227, 221, 166, 1)", }}>
          <label className='text-patient-rec col-4 ' >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Procedure</b></label>
          <label className='text-patient-rec col-2' ><b>Date</b></label>
          <label className='text-patient-rec col-2' ><b>Area</b></label>
          <label className='text-patient-rec col-2' ><b>Type</b></label>
          {/* <label className='text-patient-rec col-1' ><b>Sessions</b></label> */}
          <label className='text-patient-rec col-2' ><b>Added by</b></label>
        </div>}
      </div>
      <SimpleBar style={{ maxHeight: '800px', paddingLeft: "2%", paddingRight: "2%" }}>
        {procedureList.length > 0 ? procedureList.map((item, index) => {
          return (
            <div className="pro_lists row">
              <label className='text-patient-rec col-4 ' style={{ textAlign: "left" }}> <i
                disabled={login_datas.user_type !== "hospital_doctor" && login_datas.user_type !== "hospital_allied_prof"}
                style={{ color: (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") ? "red" : "gray", cursor: (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") ? "pointer" : "not-allowed" }}
                className="fas fa-trash-alt"
                onClick={() => {
                  if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
                    deleteRowScan(item.id);
                  }
                }}
              ></i>&nbsp;&nbsp;{item.procedure_name}</label>
              <label className='text-patient-rec col-2' >{item.date}</label>
              <label className='text-patient-rec col-2' >{item.area}</label>
              <label className='text-patient-rec col-2' >{item.procedure_type}</label>
              {/* <label className='text-patient-rec col-1' >{item.session}</label> */}
              <label className='text-patient-rec col-2 ' >{item.created_by}   </label>

            </div>
          )
        }) : <div className="" style={{ marginTop: "10%", color: "red" }}>No Procedures!!</div>}
      </SimpleBar>
      {deleteScanConfirmModalShow ? (
        <DeleteScanConfirmPopup
          show={deleteScanConfirmModalShow}
          onHide={() => setDeleteScanConfirmModalShow(false)}
          index={scanIndexToDelete}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default Procedure
