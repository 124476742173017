import React, { useState, useEffect, } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import "./pagination.css";
import './userlist.css'
import axios from "axios";
import { useAlert } from "react-alert";
import { APIURL,TZ } from '../../Global'
import { Modal } from 'react-bootstrap';
import AddDoc from './UserListAddDoc';
import ZoomableImage from '../BDO/Admission/ZoomableImage';
import "simplebar-react/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import bed from "../../assets/images/icons/bed.png";
import ward from "../../assets/images/icons/ward.png";
import AssignPatient from './RoomAssignment/AssignPatient';
import {
  Badge,

  Card,


} from "react-bootstrap";
import AssignRoomPat from './RoomAssignment/AssignRoomPat';
function UserListDr() {
  const alert = useAlert()
  const navigate = useNavigate();
  const [roomupdate,setRoomupdate]=useState(false)
  const [patientList, setPatientList] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(20);
  const [hasNext, setHasNext] = useState(true)
  const [page, setPage] = useState(0)
  const [load, setLoad] = useState(false)
  const [display, setDisplay] = useState(false)
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [addShow, setAddShow] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [info, setInfo] = useState("")
  const [reports, setReports] = useState([])
  const [perPage1] = useState(10);
  const [hasNext1, setHasNext1] = useState(true)
  const [page1, setPage1] = useState(0)
  const [assign,setAssign]= useState("")
  const [load1, setLoad1] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [reassign,setReassign]=useState(false)
  const [searching,setSearching]=useState(false)
  const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
  const handleLoad = async (page, per_page) => {
  
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
    axios.get(`${APIURL}/api/v1/doctor/patient-record-search/?per_page=${perPage+1}&page=${page}&${TZ}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        /* this to be changed once API is fixed */

        if (res.data.status === "success") {
          let Data = []
        setRoomupdate(false)
          setHasNext(res.data.has_next)
          setPage(res.data.page)

          Data = res.data.patients
            .filter(function (o1) {

              return !patientList.some(function (o2) {
                return o1.id === o2.id;
              });
            })

          setPatientList([...patientList, ...Data]);

        } else {
          // setErrorMsg("error");
        }


      })
      .catch(err => {

        // setErrorMsg("error");
      })

  };
  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
style={{}}
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: "left",minHeight:"400px", textAlign:"left"}}>
           <AssignRoomPat from="admission" reassign={reassign} assign={assign} onSuccess={(data) => { setShow1(false);
            window.location.reload(false); 
            }} /> 
          
        </Modal.Body>
      </Modal>
    );
  };
  useEffect(() => {
    if(searching){
      const tokenString = sessionStorage.getItem('usertoken');
      let str = tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */
       let url = `${APIURL}/api/v1/doctor/patient-record-search/?q=${searchTerm}`
        axios
            .get(url, {
              headers: {

                "Authorization": 'Token ' + str
              }
            })
            .then((res) => {
                if (res.data.status === "success") {
                  setPatientList(res.data.patients)
                }
                
            }).catch((error) => {

            })
          }setSearching(false)
}, [searchTerm])


  const deleteItem = (id1) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    axios.put(`${APIURL}/api/v1/doctor/patient-documents-view/?id=${id1}&patient_id=${id} `, { is_deleted: true }, {
      headers: {
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {

          alert.success("Deleted successfully")
          reports.splice(0, reports.length + 1)
          setRefresh(!refresh)

        } else {


        }

      })
      .catch(err => {


      })

  }
 
  useEffect(() => {

    if (hasNext1) {
      GetReport(page1 + 1, perPage1)
    }

  }, [load1])
  useEffect(() => {
    GetReport(1, 10)

  }, [id, refresh])
  useEffect(() => {
if (roomupdate){
  handleLoad(1, perPage)
}
    else if (hasNext) {
     
      handleLoad(page + 1, perPage)
    }

  }, [load])
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ paddingRight: "7%", textAlign: "center" }}>
          <ZoomableImage src={info} />
        </Modal.Body>
      </Modal>
    );
  };
  const GetReport = async (page, per_page) => {
if(id!==""){
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    await axios
      .get(
        `${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${id}&per_page=20&page=${page}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          let Data = []

          setHasNext1(res.data.has_next)
          setPage1(res.data.page)

          Data = res.data.results
            .filter(function (o1) {

              return !reports.some(function (o2) {
                return o1.id === o2.id;
              });
            })

          setReports([...reports, ...Data]);
          setLoad1(!load1)
        } else {
          // setErrorMsg("error");
        }
      });
    }

  };

  const onSuccess = () => {

    setAddShow(false)
    GetReport(1, 10)

  };
  const AddPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >

        <Modal.Body style={{ width: "95%", minHeight: "450px", paddingRight: "5%" }}>
          <h6 style={{ marginLeft: "2%", textTransform: "capitalize" }}><b>{name}</b>
            <button style={{ backgroundColor: "white", float: "right" }} variant="secondary" onClick={props.onHide}>
              <i class="fa fa-times fa-lg" style={{ color: "black" }} aria-hidden="true"></i>
            </button></h6>
          <AddDoc id={id} onSuccess={onSuccess} onClose={props.onHide} />


        </Modal.Body>

      </Modal>
    )
  }


  const handleSearchChange = (e) => {
    if(e.target.value!==''){
    setSearching(true)
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
    }else{
    setSearching(false)
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
    handleLoad(1,20)
    }
  };
  const handlePageChange = (pageNumber) => {
    if (pageNumber > currentPage) {
      setLoad(!load)
    }

    setCurrentPage(pageNumber);
  };
  let data = JSON.parse(JSON.stringify(patientList))
   
  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current1 = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;




  return (<>



    <div className="search-patient-page directory-page container" style={{margin:"auto"}}>

      <div style={{margin:"auto"}}>
       
          <h4 className=" title-of-tasks"> Patient Records Directory </h4><br />
          <div className="search-section search-div" >

            <form style={{ display: "flex" }}>

              <input
                className="form-control search-input"
                type="text"
                placeholder="Enter Name"
                value={searchTerm}
                maxLength="150"
                onChange={handleSearchChange}
              />

              <button className="btn btn-primary btn-col search-btn">

                <i className="fas fa-search"></i>
              </button>
            </form>     </div>
        </div><br/>
      
      <div className='' style={{margin:"auto"}}>
        <div  >

          <div className="row">
            {
              current1.length > 0 ?
              current1.map((value, index) => {
                  // 

                  return (
                   
                    <div className='col-6'>
                    <div className='list-item-new' style={{ backgroundColor: display && id === value.id && "rgba(222, 214, 138, 1)" }}>
                    <div className='row' style={{paddingTop:"1%",paddingBottom:"1%"}}>
                   
                    <div className="col-2" style={{ alignContent: 'center', padding: 0 }}> <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">View Profile</Tooltip>}>
                           <button className="dir-button" style={{ backgroundColor: "white",width:"100%" }}onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                             state: { },
                           })}>
                      <img src={value.photo } alt="Profile Photo" style={{
                        maxWidth: "none",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                      }} /></button></OverlayTrigger>
                    </div>
                   
                    <div className="col-10" style={{ alignContent: 'left' }}> 
                           <button className="dir-button1" style={{ backgroundColor: "white",width:"100%" }} onClick={() => navigate(`/patient-record/id/${value.id}/mob/${value.mobile_number}`, {
                             state: { },
                           })}>
                    <h6 style={{ letterSpacing:"0px", fontSize:"medium",textTransform:"capitalize",lineHeight:"15px",color:"#4b9cdf"}} className="each-list-link2" >&nbsp;{value.name}</h6>
                    </button>
                    <div className='row'>
                  
                      <div className='col-4'style={{display:"flex",alignItems: "center",}} > <p className='each-list-link2' style={{fontSize:"medium"}}><i class="fa fa-address-card fa-lg" aria-hidden="true"  style={{fontSize:"1rem"}}></i>&nbsp; {value.id} </p></div>
                       <div className='col-4' style={{display:"flex",alignItems: "center"}}> <p className='each-list-link2' style={{fontSize:"medium"}}> <i class="fa fa-phone fa-lg" aria-hidden="true"style={{fontSize:"1rem"}}></i>&nbsp; {value.mobile_number} </p>
                        
                      </div>
                      <div className='col-4' style={{display:"flex",alignItems: "center",}}>
                        
                        <p onClick={() => {if(login_datas.user_type === 'hospital_bdo'|| login_datas.user_type === 'hospital_manager'){
            setAssign(value)
            if(value.bedunit!==null){
                setReassign(true)
            }
            else{
              setReassign(false)
            }
                
                 setShow1(true)}
               }} className='each-list-link2' style={{fontSize:"medium",color:"#4b9cdf",cursor:login_datas.user_type === 'hospital_bdo'|| login_datas.user_type !== 'hospital_manager'?"pointer":"default"}}>
                              {value.bedunit!==null? <img
                      src={value.bedunit.bedspace_type==="ward"?ward:value.bedunit.bedspace_type==="room"?bed:""}
                      alt="Ward"
                      style={{ width: "25%", borderRadius: "50%",backgroundColor:"white", marginTop:"-5px",padding: "1%"}}
                    />:<>&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M7 4v6M4 7h6m.5-6.5h-7a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3"/></svg>&nbsp; Add</>}&nbsp; {value.bedunit!==null&&value.bedunit.bedunit_code}
    </p>
                           
                          </div>
                      </div>
                    </div>
                
             
                
                    </div>
                     
 

                   </div>
                    </div>
                 



                  )
                }) : <h6 style={{ color: "red" }}>No Patients Found</h6>
            }

          </div>


          <br />
          <br />
          {totalPages > 0 ?
            <div className="pagn" >

              <Pagination
                activePage={currentPage}
                itemsCountPerPage={perPage}
                totalItemsCount={total}
                pageRangeDisplayed={totalPages}
                onChange={handlePageChange}
                disabledClass="disabled-class"
              />
            </div>
            : null}
        </div>
      
      </div>

    </div >
    {
      addShow ?
        <AddPopUp
          show={addShow}
          onHide={() => setAddShow(false)}

        /> : null
    }
    {show ? (
      <InfoPopup
        show={show}
        onHide={() => {
          setShow(false);
        }}
      />
    ) : (
      ""
    )}
  {show1 ? (
        <ViewPopup
          show={show1}
          onHide={() => {
            setShow1(false);
          }}
        />
      ) : (
        ""
      )}
  </>
  )
}


export default UserListDr;