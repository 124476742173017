import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import bed from "../../../assets/images/icons/bed.png";
import ward from "../../../assets/images/icons/ward.png";
import { APIURL, TZ } from "../../../Global";
import { useAlert } from "react-alert";
import axios from "axios";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { NavDropdown } from "react-bootstrap";

function FloorDetails(props) {

    const [wardData, setWardData] = useState([]);
    const [roomData, setRoomData] = useState([]);
    const [deluxe, setDeluxe] = useState([]);
    const [semi, setSemi] = useState([]);
    const [executive, setExecutive] = useState([]);
    const [vip, setVip] = useState([]);
    const [vvip, setVVip] = useState([]);
    const [std, setStd] = useState([]);
    const [wardSelect, setwardSelect] = useState(null);
  
    useEffect(() => {
        if(!props.propsvar.from){
        if (deluxe.length > 0) {
            props.bedSpace("room")
            props.bedUnitCode(deluxe[0].bedunit_code);
            setwardSelect(deluxe[0].bedunit_code)
        }
        else if (semi.length > 0) {
            props.bedSpace("room")
            props.bedUnitCode(semi[0].bedunit_code);
            setwardSelect(semi[0].bedunit_code)
        }
        else if (executive.length > 0) {
            props.bedSpace("room")
            props.bedUnitCode(executive[0].bedunit_code);
            setwardSelect(executive[0].bedunit_code)
        }
        else if (vip.length > 0) {
            props.bedSpace("room")
            props.bedUnitCode(vip[0].bedunit_code);
            setwardSelect(vip[0].bedunit_code)
        }
        else if ( vvip.length > 0) {
            props.bedSpace("room")
            props.bedUnitCode(vvip[0].bedunit_code);
            setwardSelect(vvip[0].bedunit_code)
        }
        else if (std.length > 0) {
            props.bedSpace("room")
            props.bedUnitCode(std[0].bedunit_code);
            setwardSelect(std[0].bedunit_code)
        }
        else if(wardData.length>0){
            props.bedSpace("ward")
            props.bedUnitCode(wardData[0].bed_availability[0].bedunit_code);
            setwardSelect(wardData[0].bed_availability[0].bedunit_code)
        }
        else{
            props.bedSpace("")
            props.bedUnitCode("");
            setwardSelect("")
            setwardSelect("")
        }
        
    }
   
    
    }, [deluxe, semi, executive, vip, vvip, std,wardData])
    useEffect(() => {
        setDeluxe(
            roomData.filter((item) => {
                return item.room_type === "deluxe";
            })
        );
        setSemi(
            roomData.filter((item) => {
                return item.room_type === "semi-deluxe";
            })
        );
        setExecutive(
            roomData.filter((item) => {
                return item.room_type === "executive";
            })
        );
        setVip(
            roomData.filter((item) => {
                return item.room_type === "vip";
            })
        );
        setVVip(
            roomData.filter((item) => {
                return item.room_type === "vvip";
            })
        );
        setStd(
            roomData.filter((item) => {
                return item.room_type === "standard";
            })
        );
    }, [roomData]);
    useEffect(() => {
        if(props.floor!==null){
        const tokenString = sessionStorage.getItem("usertoken");
        const v = tokenString;
        let str = v.replace(/["]+/g, "");
        axios
        .get(
            `${APIURL}/api/v1/service-provider/room-availability/?rooms=True&floor=${props.floor}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + tokenString.replace(/["]+/g, ""),
                },
            }
        )
        .then((res) => {
            if (res.status === 200) {
                setRoomData(res.data.room_data);

            }
        });
        axios
            .get(
                `${APIURL}/api/v1/service-provider/room-availability/?ward=True&floor=${props.floor}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + tokenString.replace(/["]+/g, ""),
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setWardData(res.data.ward_data);


                }
            });
        }

    }, [props.floor, props.refresh])


    const displayBed = (ward, index,type) => {
        return (<>
            <div style={{height:"60px",marginBottom:"2%"}} className="col-2"
              onClick={() => {
                        if (wardSelect !== ward.bed_number) {
                            if (ward.is_occupied) {
                                props.assigned(true);
                            } else {
                                props.assigned(false);
                            }
                            props.bedSpace("ward")
                            props.bedUnitCode(ward.bedunit_code);
                            setwardSelect(ward.bedunit_code);
                            
                        }
                    }}
            key={index}>
                <div style={{ background: ward.is_occupied
                            ? wardSelect === ward.bedunit_code
                                ? "#7B4A00"
                                : "#D69028"
                            : wardSelect === ward.bedunit_code
                                ? "#295A04"
                                : "#BEE59F",
                                 height: "30%",
                            border: "1px solid #6F6F6F",
                        borderRadius:"4px 4px 0px 0px",}}></div>
                         <div
                        style={{
                            
                            paddingTop:"10%",
                            height:"70%",
                            textAlign: "center",
                            border: "1px solid #6F6F6F",
                            background: "white",
                            borderRadius:"0px 0px 4px 4px",
                            color:
                                type === "deluxe"
                                    ? "#F70D1A "
                                    : type === "semi-deluxe"
                                        ? "#FF4500"
                                        : type === "executive"
                                            ? "#C24641"
                                            : type === "vvip"
                                                ? "#872657"
                                                : type === "vip"
                                                    ? "blue"
                                                    :
                                                     "green",
                            cursor: ward.is_occupied
                                ? "default"
                                : "pointer",
                        }}>
                        {"bed" + (index + 1)}
                    </div>
            </div>
            
            </>
        );
    };

    const displayRoom = (room, index) => {
        return (
            <>
                <div style={{height:"60px",marginBottom:"2%"}} onClick={() => {
                            if (wardSelect !== room.room_id) {
                                if (room.is_occupied) {
                                    props.assigned(true)
                                }
                                else {
                                    props.assigned(false)
                                }
                                props.bedSpace("room")
                                props.bedUnitCode(room.bedunit_code);
                                setwardSelect(room.bedunit_code);
                            }
                        }} className="col-3">
                    <div style={{
                        height: "30%",
                        borderRadius:"4px 4px 0px 0px",
                        background: room.is_occupied
                            ? wardSelect === room.bedunit_code
                                ? "#7B4A00"
                                : "#D69028"
                            : wardSelect === room.bedunit_code
                                ? "#295A04"
                                : "#BEE59F",
                        border: "1px solid #6F6F6F",
                    }}>

                    </div>
                    <div
                        style={{
                            
                            paddingTop:"6%",
                            height:"60%",
                            textAlign: "center",
                            border: "1px solid #6F6F6F",
                            background: "white",
                            borderRadius:"0px 0px 4px 4px",
                            color:
                                room.room_type === "deluxe"
                                    ? "#F70D1A "
                                    : room.room_type === "semi-deluxe"
                                        ? "#FF4500"
                                        : room.room_type === "executive"
                                            ? "#C24641"
                                            : room.room_type === "vvip"
                                                ? "#872657"
                                                : room.room_type === "vip"
                                                    ? "blue"
                                                    : "green",
                            cursor: room.is_occupied
                                ? "default"
                                : "pointer",
                        }}>
                        <h6> Room {room.room_number}</h6>
                    </div>
                </div>
                
            </>
        )
    }
    const displaywards = (item, index) => {
        return (
            <div className="displayrooms" style={{marginTop:"2%"}} key={index}>
                <h6
                    style={{
                        textAlign: "inline",
                        borderBottom: "1px solid #C6C6C6",
                        padding: "2%",
                    }}
                >
                    <img
                        src={ward}
                        alt="Ward"
                        style={{ width: "5%", marginTop: "-2%" }}
                    />
                    &nbsp;&nbsp;
                    <b>{item.ward_name}-Ward</b>
                    <div
                        style={{
                            float: "right",
                            marginRight: "1%",
                            display: "flex",
                            fontSize: "small",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "2%",
                                width: "42px",
                                height: "10px",
                                flexShrink: "0",
                                borderRadius: "2px",
                                background: "#BEE59F",
                            }}
                        ></div>
                        <small style={{ marginLeft: "5px" }}>Available</small>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div
                            style={{
                                marginTop: "2%",
                                width: "42px",
                                height: "10px",
                                flexShrink: "0",
                                borderRadius: "2px",
                                background: "#D69028",
                            }}
                        ></div>
                        <small style={{ marginLeft: "5px" }}>Not Available</small>
                    </div>
                </h6>
                <SimpleBar style={{ maxHeight: "200px" }}>
                <div style={{width:"100%",margin:"2%"}}>
                    <h6
                        style={{
                            textTransform: "uppercase",
                            color: item.ward_type === "deluxe" ? "#F70D1A "
                                : item.ward_type === "semi-deluxe" ? "#FF4500 "
                                    : item.ward_type === "executive" ? "#C24641 "
                                        : item.ward_type === "vvip" ? "#872657 "
                                            : item.ward_type === "vip" ? "blue" : "green"

                        }}
                    >
                        {item.ward_type}-
                        <span style={{ fontWeight: "600" }}>
                            <i class="fa fa-eur" aria-hidden="true"></i>
                            {item.bed_price}
                        </span>
                    </h6>
                  
                    <div style={{width:"95%"}}className="row">
                        {item.bed_availability.map((ward, i) => displayBed(ward, i,item.ward_type))}
                    </div>
                    </div>
                </SimpleBar>
            </div>
        );
    };


    return (
        <div>


            <>
                <div
                    className="displayrooms"
                    style={{
                        marginTop: "15px",
                        // paddingRight: "5%",
                        paddingBottom: "1%",
                    }}
                >
                    <h6
                        style={{
                            textAlign: "inline",
                            borderBottom: "1px solid #C6C6C6",
                            padding: "2%"

                        }}
                    >  <img
                            src={bed}
                            alt="Room"
                            style={{ width: "4%", marginTop: "-10px" }}
                        />&nbsp;&nbsp;
                        <b >Rooms</b>
                        <div
                            style={{
                                float: "right",
                                marginRight: "1%",
                                display: "flex",
                                fontSize: "small"
                            }}
                        >
                            <div
                                style={{
                                    marginTop: "2%",
                                    width: "42px",
                                    height: "10px",
                                    flexShrink: "0",
                                    borderRadius: "2px",
                                    background: "#BEE59F",
                                }}
                            ></div>
                            <small style={{ marginLeft: "5px", }}>Available</small>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div
                                style={{
                                    marginTop: "2%",
                                    width: "42px",
                                    height: "10px",
                                    flexShrink: "0",
                                    borderRadius: "2px",
                                    background: "#D69028",
                                }}
                            ></div>
                            <small style={{ marginLeft: "5px" }}>Not Available</small>
                        </div>
                    </h6>

                    <SimpleBar style={{ maxHeight: "200px" }}>
<div style={{width:"100%",margin:"2%"}}>
{deluxe.length > 0 &&  <div style={{ width:"95%"}} className="row">
                            {deluxe.length > 0 && (
                                <>
                                    <h6
                                        style={{
                                            textTransform: "uppercase",
                                            color: "#F70D1A ",
                                        }}
                                    >
                                        {deluxe[0].room_type}-
                                        <span style={{ fontWeight: "600" }}>
                                            <i class="fa fa-eur" aria-hidden="true"></i>
                                            {deluxe[0].room_price}
                                        </span>
                                    </h6>
                                    <br />
                                </>
                            )}
                            {deluxe && <>{
                                deluxe.map((room, index) => (
                                    displayRoom(room, index)
                                ))}<br /></>}
                        </div>}

                        {semi.length > 0 &&  <div style={{ width:"95%"}} className="row">
                            {semi.length > 0 && (
                                <>
                                    <h6
                                        style={{
                                            textTransform: "uppercase",
                                            color: "#FF4500",
                                        }}
                                    >
                                        {semi[0].room_type}-
                                        <span style={{ fontWeight: "600" }}>
                                            <i class="fa fa-eur" aria-hidden="true"></i>
                                            {semi[0].room_price}
                                        </span>
                                    </h6>
                                    <br />
                                </>
                            )}
                            {semi && <>{
                                semi.map((room, index) => (
                                    displayRoom(room, index)
                                ))}<br /></>}
                        </div>}

                        {executive.length > 0 && <div style={{ width:"95%"}} className="row">
                            {executive.length > 0 && (
                                <>
                                    <h6
                                        style={{
                                            textTransform: "uppercase",
                                            color: "#C24641",
                                        }}
                                    >
                                        {executive[0].room_type}-
                                        <span style={{ fontWeight: "600" }}>
                                            <i class="fa fa-eur" aria-hidden="true"></i>
                                            {executive[0].room_price}
                                        </span>
                                    </h6>
                                    <br />
                                </>
                            )}
                            {executive && <>{
                                executive.map((room, index) => (
                                    displayRoom(room, index)
                                ))}<br /></>}
                        </div>}

                        {vip.length > 0 &&<div style={{ width:"95%"}} className="row">
                            {vip.length > 0 && (
                                <>
                                    {" "}
                                    <h6
                                        style={{ textTransform: "uppercase", color: "blue " }}
                                    >
                                        {vip[0].room_type}-
                                        <span style={{ fontWeight: "600" }}>
                                            <i class="fa fa-eur" aria-hidden="true"></i>
                                            {vip[0].room_price}
                                        </span>
                                    </h6>
                                    <br />
                                </>
                            )}
                            {vip && <>{
                                vip.map((room, index) => (
                                    displayRoom(room, index)
                                ))}<br /></>}
                        </div>}

                        {vvip.length > 0 && <div style={{ width:"95%"}} className="row">
                            {vvip.length > 0 && (
                                <>
                                    {" "}
                                    <h6
                                        style={{
                                            textTransform: "uppercase",
                                            color: "#872657 ",
                                        }}
                                    >
                                        {vvip[0].room_type}-
                                        <span style={{ fontWeight: "600" }}>
                                            <i class="fa fa-eur" aria-hidden="true"></i>
                                            {vvip[0].room_price}
                                        </span>
                                    </h6>
                                    <br />
                                </>
                            )}
                            {vvip && <>{
                                vvip.map((room, index) => (
                                    displayRoom(room, index)
                                ))}<br /></>}
                        </div>}

                        {std.length > 0 &&<div style={{ width:"95%"}} className="row">
                            {std.length > 0 && (
                                <>
                                    <h6
                                        style={{ textTransform: "uppercase", color: "green" }}
                                    >
                                        {std[0].room_type}-
                                        <span style={{ fontWeight: "600" }}>
                                            <i class="fa fa-eur" aria-hidden="true"></i>
                                            {std[0].room_price}
                                        </span>
                                    </h6>
                                    <br />
                                </>
                            )}
                            {std &&
                                <>{
                                    std.map((room, index) => (
                                        displayRoom(room, index)
                                    ))}<br /></>}
                        </div>}
                        </div>
                    </SimpleBar>
                </div>
            </>

            <>

                {wardData.map((item, index) => displaywards(item, index))}



            </>

        </div>
    )
}
export default FloorDetails;