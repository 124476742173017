import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../BDO/addbdo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../BDO/dob.css";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import { APIURL,TZ } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";
import { useAlert } from "react-alert";

function AddRoom(props) {
    const alert = useAlert();
    const [error, setError] = useState({});
    const [roomdata, setRoomdata] = useState({
        roomname: "",
        roomnumber: "",
        roomtype: { value: "", label: "" },
        roomprice: "",
      });
    const roomtypes = [
        { value: "deluxe", label: "Deluxe" },
        { value: "semi-deluxe", label: "Semi-Delux" },
        { value: "executive", label: "Executive" },
        { value: "vip", label: "VIP" },
        { value: "vvip", label: "VVIP" },
        { value: "standard", label: "Standard" },
      ];
      const validate = () => {
        let input = roomdata;
        let focussed = false;
        let errors = {};
        let isValid = true;
        setError({});
    
        if (!input["roomname"] || input["roomname"] === "") {
          isValid = false;
          errors["roomname"] = "Enter room name";
        }
    
        if (!input["roomnumber"] || input["roomnumber"] === "") {
          isValid = false;
          errors["roomnumber"] = "Enter room number";
        }
        if (/^0[0-9]+$/.test(input["roomnumber"]) || (input["roomnumber"]==="0")) {
          isValid = false;
          errors["roomnumber"] = "Room number cannot start with zero";
           }
    
        if (
          typeof roomdata.roomtype.value == "undefined" ||
          roomdata.roomtype.value === "" ||
          roomdata.roomtype.value === null
        ) {
          isValid = false;
          errors["roomtype"] = "Select room type";
        }
    
        if (!input["roomprice"] || input["roomprice"] === "") {
          isValid = false;
          errors["roomprice"] = "Enter room price";
        }
    
        setError((currentstate) => ({
          ...currentstate,
          ...errors,
        }));
    
        return isValid;
      };
    const handleAddRoom = (e) => {
    
        e.preventDefault();
    
        if (validate()) {
          const tokenStr = sessionStorage.getItem("usertoken");
    
          if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
    
            const dataToSend = {
              room_name: roomdata.roomname,
              room_number: roomdata.roomnumber,
              room_type: roomdata.roomtype.value,
              room_price: roomdata.roomprice,
              building: props.building,
              floor: props.floor,
            };
            axios
              .post(`${APIURL}/api/v1/service-provider/add-rooms/`, dataToSend, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Token " + v2,
                },
              })
              .then((res) => {
                if (res.data.status === "success") {
                  alert.success(res.data.message);
    
                  props.onSuccess()
                  setRoomdata({
                    roomname: "",
                    roomnumber: "",
                    roomtype: { value: "", label: "" },
                    roomprice: "",
                  });
                } else {
                  alert.error(res.data.message);
                }
              })
              .catch((err) => {});
          }
        }
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
    if(name==="roomprice"&&value<100000000){
        setRoomdata((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      else{
        setRoomdata((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      };
return(<>
 <label
      style={{
        color: "#212121",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: "500",
        lineHeight: "21px",
       
      }}
    >
      Create Rooms
    </label>
    <form className="roomsouter" onSubmit={handleAddRoom}>
        
   
   
    <div className="row" style={{ justifyContent: "center" }}>
      <div className="col-3">
        <input
          style={{
            height: "40px",
            flexShrink: "0",
            width:"100%",
            borderRadius: "10px",
            border: "1px solid #9A9A9A",
            background: "#FFF",
            color: "#000",
          }}
          type="text"
          placeholder="Room Name"
          name="roomname"
          value={roomdata.roomname}
          onChange={handleChange}
        />
        {error.roomname ? (
          <div
            style={{ zIndex: "auto" }}
            className="error-validation-msg"
          >
            {error.roomname}
          </div>
        ) : null}
      </div>
      <div className="col-3">
        <input
          type="number"
          min="1"
          placeholder="Room No"
          name="roomnumber"
          value={roomdata.roomnumber}
          onChange={handleChange}
          style={{
            height: "40px",
            flexShrink: "0",
            width:"100%",
            borderRadius: "10px",
            border: "1px solid #9A9A9A",
            color: "#000",
            background: "#FFF",
          }}
        />
        {error.roomnumber ? (
          <div
            style={{ zIndex: "auto" }}
            className="error-validation-msg"
          >
            {error.roomnumber}
          </div>
        ) : null}
      </div>
      <div className="col-2">
        <select
          style={{
            width: "100%",
            height: "40px",
            flexShrink: "0",
            borderRadius: "10px",
            border: "1px solid #9A9A9A",
            color: "#000",
            background: "#FFF",
          }}
          value={roomdata.roomtype.value} // Set the value property instead of the whole object
          onChange={(e) => setRoomdata((current) => ({
            ...current,
            roomtype: {
              value: e.target.value,
              label: e.target.value, // You can set the label as the same value if desired
            },
          }))} // Pass the selected value instead of the whole event
          name="roomtype"
          className=""
        >
          <option value="">Select Room Type</option>{" "}
          {/* Add a default option */}
          {roomtypes.map((roomtype) => (
            <option key={roomtype.value} value={roomtype.value}>
              {roomtype.label}
            </option>
          ))}
        </select>
        {error.roomtype ? (
          <div
            style={{ zIndex: "auto" }}
            className="error-validation-msg"
          >
            {error.roomtype}
          </div>
        ) : null}
      </div>
   
      <div className="col-2">
        {" "}
        <input
          type="number"
          min="0"
          placeholder="Room Price"
          name="roomprice"
          value={roomdata.roomprice}
          onChange={handleChange}
          style={{
            height: "40px",
            flexShrink: "0",
            borderRadius: "10px",
            width:"100%",
            color: "#000",
            border: "1px solid #9A9A9A",
            background: "#FFF",
          }}
        />
        {error.roomprice ? (
          <div
            style={{ zIndex: "auto" }}
            className="error-validation-msg"
          >
            {error.roomprice}
          </div>
        ) : null}
      </div>
      <button
        className="facilitybuttons col-1"
        style={{
          paddingTop: "15px",
          cursor: "pointer",
          height: "40px",
          
          
        }}
      >
       Done
      </button>
    </div>
   </form>
   </>
)
}
export default AddRoom;