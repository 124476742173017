import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { APIURL, TZ } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";
function AddAller(props) {
  const alert = useAlert();
  const [allerData, setAllergyData] = useState("");
  const [allergy, setAllergy] = useState([]);
  // const [modalShow, setModalShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [display, setDisplay] = useState(false);
  // const [errorShow, setErrorShow] = useState(false);
  const [allerduration, setAllerDuration] = useState("");
  const inputRefs = useRef([])
  const [other, setOther] = useState(false);
  const [type, setType] = useState("")
  const [type1, setType1] = useState("")
  const saveButtonRef = useRef(null)
  const [otherVal, setOtherVal] = useState("");
  const [reactionArray, setReactionArray] = useState([])
  const [severity, setSeverity] = useState("")
  const [description, setDescription] = useState('')
  const [reactions, setReactions] = useState({
    Altered_mental_status: false,
    Anemia: false,
    Anaphylactic_shock: false,
    Bronchospasm: false,
    Vitamin_deficiency: false,
    Allergy: false,
    Headache: false,
    Angioedema: false,
    Cardiac_dysrhythmia: false,
    Cough: false,
    Skin_rash: false,
    Hepatic_toxicity: false,
    Wheal: false,
    Diarrhea: false,
    Unknown: false,
    Dystonia: false,
    Fever: false,
    Flushing: false,
    Gastrointestinal_irritation: false,
    High_blood_pressure: false,
    Itching_of_skin: false,
    Musculoskeletal_pain: false,
    Myalgia: false,

  })
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault()
      if (index === 1) {
        saveButtonRef.current.click();
      } else {
        e.preventDefault();
        const nextIndex = index + 1;
        if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
          inputRefs.current[nextIndex].focus();
        }
      }
    } else if (e.altKey && e.key === 's') {
      saveButtonRef.current.click();
    }
  };


  const handleAllerduration = (e) => {
    ;
  };


  const handleClick = (item) => {
    initialRender.current = true;
    setSearchTerm(item.allergy_name);
    setType(item.allergy_type)
    setAllergyData(item.allergy_name);
    // setPatientDetails(item)

    setDisplay(false);
    // setAllData([])
    // setRefresh(!refresh)
  };
  const handleDisplay = () => {
    setDisplay(false);

  }
  useEffect(() => {
    document.addEventListener('click', handleDisplay)

    return () => {
      document.removeEventListener('click', handleDisplay)
    }
  }, [])
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setAllergyData("")
    setType("")
  };

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setAllergy([]);
      setAllergyData("");
      setDisplay(false);
    } else {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(`${APIURL}/api/v1/doctor/allergies/?search=${searchTerm}&${TZ}`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {

          if (res && res.data) {
            setAllergy(res.data);

            setDisplay(true);
            // setTotalPages(res.data.total_pages)
            // setCurrentPage(res.data.page)
            // setTotalRecords(res.data.total_records)
          } else {
          }
        })
        .catch((error) => { });
    }
  }, [searchTerm]);

  const handleSave = () => {


    if (searchTerm === "" || searchTerm === null) {
      alert.error("Select a valid allergy!")
    } else if (allerduration === null || allerduration < 0.1) {
      alert.error("Enter duration!")
    } else if ((type === null || type === "") && (type1 === null || type1 === "")) {
      alert.error("Select type!")
    } else if (severity === null || severity === "") {
      alert.error("Select severity!")
    }

    else {
      if (otherVal !== "") {
        reactionArray.push(otherVal)
      }
      const id = props.id;
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");

      const dataToUpdate = {
        allergy: searchTerm,
        patient: id,
        duration: allerduration,
        reaction: reactionArray,
        allergy_type: type !== null && type !== "" ? type : type1,
        severity: severity,
        description: description
      };
      ;

      axios
        .post(`${APIURL}/api/v1/doctor/allergies-list/`, dataToUpdate, {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
          ;
          if (res.data.status === "success") {
            alert.success("Allergy added successfully")
            props.handleAdd();
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => {
          alert.error("error in submission")
        });
    }
  };
  useEffect(() => {
    let updatedArray = [];
    for (var key in reactions) {
      if (reactions[key] === true) {
        updatedArray.push(key);
      }
    }
    setReactionArray(updatedArray);


  }, [reactions]);


  const handlereactions = (e) => {
    const key = e.target.name;

    setReactions((currentstate) => ({
      ...currentstate,
      [key]: !reactions[key],
    })
    );

  };


  return (
    <div className="flex-col">
      <div style={{ width: "100%" }} >
        <input
          ref={el => inputRefs.current[0] = el}
          onKeyDown={(e) => handleKeyDown(e, 0)}
          className="enquiryinput"
          type="text"
          maxLength="100"
          placeholder="Search allergen"
          value={searchTerm}
          style={{ width: "100%" }}
          onChange={handleSearchChange}
        // value={comorbValue}

        // onChange={handleChange}


        />
        {display ? (
          <div className="suggest-display adjust-dimensions comor-auto-suggest ">
            {allergy.length > 0 ? (
              allergy.map((item) => {
                return (
                  <p
                    onClick={() => handleClick(item)}
                    className="suggest-item text-secondary"
                  >
                    {item.allergy_name} <span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span>
                    {item.allergy_type && <span style={{ fontSize: "16px", backgroundColor: "#5587b573", borderRadius: "3px" }}>{" "}{item.allergy_type}{" "}</span>}

                  </p>
                );
              })
            ) : (
              <h6 className="text-muted">No Allergen found !!</h6>
            )}
          </div>
        ) : null}

      </div><br />
      {searchTerm !== "" && <div>
        <div className='' style={{ padding: "1%" }}>
          <div className="row">

            <div className='sub-head-div col-9' style={{ textAlign: "left" }}>
              <div className="row">
                <h6 className="col-8" style={{ padding: "1%", marginTop: "1.5%" }}><b>  {searchTerm} </b><span>&nbsp;&nbsp;</span> </h6>
                {type !== "" && type !== null ? <span className="col-4" style={{ fontSize: "16px", paddingTop: "1.5%", backgroundColor: "#5587b573", borderRadius: "3px" }}>{" "}{type}{" "}</span> :
                  <select
                    name='allergytype'
                    className="enquiryinput col-4"
                    style={{ padding: "auto", marginTop: "0.5%" }}
                    value={type1}
                    onChange={(e) => { setType1(e.target.value) }}>
                    <option value="" selected hidden  >Select type</option>
                    <option value="drug" >Drug</option>
                    <option value="food" >Food</option>
                    <option value="environment" >Environment</option>

                  </select>}
              </div>
            </div>
            <div className="col-3">

              <input
                ref={el => inputRefs.current[1] = el}
                onKeyDown={(e) => handleKeyDown(e, 1)}
                placeholder="Duration in yrs"
                type="number"
                className="enquiryinput"
                name="duration"
                value={allerduration}
                onChange={(e) => {
                  e.preventDefault();
                  setAllerDuration(e.target.value);
                }}
              ></input>



            </div>
          </div>
          <div className='row' style={{ marginTop: "2%", margin: "auto", textAlign: "left" }}>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Altered_mental_status"
                name="Altered_mental_status"
                checked={reactions.Altered_mental_status}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Altered mental status</label></>
            </div>

            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Anemia"
                name="Anemia"
                checked={reactions.Anemia}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Anemia</label></>
            </div> <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Headache"
                name="Headache"
                checked={reactions.Headache}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Headache </label></>
            </div> <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Anaphylactic_shock"
                name="Anaphylactic_shock"
                checked={reactions.Anaphylactic_shock}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Anaphylactic shock</label></>
            </div> <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Angioedema"
                name="Angioedema"
                checked={reactions.Angioedema}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Angioedema</label></>
            </div> <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Bronchospasm"
                name="Bronchospasm"
                checked={reactions.Bronchospasm}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Bronchospasm</label></>
            </div> <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Cardiac_dysrhythmia"
                name="Cardiac_dysrhythmia"
                checked={reactions.Cardiac_dysrhythmia}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Cardiac dysrhythmia</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Cough"
                name="Cough"
                checked={reactions.Cough}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Cough</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Diarrhea"
                name="Diarrhea"
                checked={reactions.Diarrhea}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Diarrhea</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Dystonia"
                name="Dystonia"
                checked={reactions.Dystonia}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Dystonia</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Fever"
                name="Fever"
                checked={reactions.Fever}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Fever</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Flushing"
                name="Flushing"
                checked={reactions.Flushing}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Flushing</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Gastrointestinal_irritation"
                name="Gastrointestinal_irritation"
                checked={reactions.Gastrointestinal_irritation}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Gastrointestinal Irritation</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="High_blood_pressure"
                name="High_blood_pressure"
                checked={reactions.High_blood_pressure}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>High blood pressure</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Itching_of_skin"
                name="Itching_of_skin"
                checked={reactions.Itching_of_skin}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Itching of skin</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Musculoskeletal_pain"
                name="Musculoskeletal_pain"
                checked={reactions.Musculoskeletal_pain}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Musculoskeletal pain</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Myalgia"
                name="Myalgia"
                checked={reactions.Myalgia}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Myalgia</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Skin_rash"
                name="Skin_rash"
                checked={reactions.Skin_rash}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Skin rash</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Hepatic_toxicity"
                name="Hepatic_toxicity"
                checked={reactions.Hepatic_toxicity}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Hepatic toxicity</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Wheal"
                name="Wheal"
                checked={reactions.Wheal}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Wheal</label></>
            </div>
            <div className='col-6' >
              <><input
                className=""
                type="checkbox"
                id="Unknown"
                name="Unknown"
                checked={reactions.Unknown}
                onChange={(e) => { handlereactions(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Unknown</label></>
            </div>
            <div className='col-6' >
              {!other ? <><input
                className=""
                type="checkbox"
                id="Other"
                name="Other"


                onClick={(e) => { setOther(true) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Other</label></> :
                <div style={{ display: "flex" }}><input className='enquiryinput'
                  maxLength="200"
                  type="text"
                  name="action_planned"
                  onChange={(e) => {
                    let val = e.target.value
                    setOtherVal(val)
                  }}
                />
                  <i onClick={(e) => { setOther(false) }} class="fa fa-times" aria-hidden="true"></i>
                </div>}

            </div>

          </div>
        </div>

        <div className='sub-head-div' style={{ textAlign: "left" }}>
          <h6 style={{ padding: "1%" }}>Severity </h6>
        </div>
        <br />
        <div className="row" style={{ margin: "auto" }}>
          <div className="col-4" >
            <><input
              className=""
              type="radio"
              checked={severity === "moderate"}
              onChange={(e) => { setSeverity("moderate") }}
            />{" "}<label className="" style={{ marginTop: "2%" }}>Moderate</label></>
          </div>
          <div className="col-4" >
            <><input
              className=""
              type="radio"
              checked={severity === "mild"}
              onChange={(e) => { setSeverity("mild") }}
            />{" "}<label className="" style={{ marginTop: "2%" }}>Mild</label></>
          </div>
          <div className="col-4" >
            <><input
              className=""
              type="radio"
              checked={severity === "severe"}
              onChange={(e) => { setSeverity("severe") }}
            />{" "}<label className="" style={{ marginTop: "2%" }}>Severe</label></>
          </div>

          <div className="col-12">

            <textarea

              onKeyDown={(e) => handleKeyDown(e, 1)}
              placeholder="Description"
              type="text"
              className="enquiryinputtext"
              style={{ height: "100px", margin: "1%" }}
              value={description}
              onChange={(e) => {
                e.preventDefault();
                setDescription(e.target.value);
              }}
            ></textarea>



          </div>

        </div><br />

        <div className="form-button">
          <button
            ref={saveButtonRef}
            type="submit" className="review-button"
            onClick={handleSave}
          >
            {" "}
            Save{" "}
          </button>
        </div>
      </div>}






    </div>
  );
}

export default AddAller;
