import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { APIURL, TZ } from "../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useAlert } from "react-alert";
function EditAller(props) {
  console.log(props)
  const alert = useAlert();
  const { data, id } = props
  const [description, setDescription] = useState(data.description)
  const [allerduration, setAllerDuration] = useState(data.duration);
  const saveButtonRef = useRef(null)
  const inputRefs = useRef([])
  const [other, setOther] = useState(false);
  const [severity, setSeverity] = useState(props.data.severity)
  const [reactionArray, setReactionArray] = useState(props.data.reaction)
  const [otherVal, setOtherVal] = useState("");
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault()
      if (index === 1) {
        saveButtonRef.current.click();
      } else if (index === 0) {
        e.preventDefault();
        const nextIndex = index + 1;
        inputRefs.current[nextIndex].focus();
      }
    }
  };
  const [reactions, setReactions] = useState({
    Altered_mental_status: false,
    Anemia: false,
    Anaphylactic_shock: false,
    Bronchospasm: false,
    Vitamin_deficiency: false,
    Allergy: false,
    Headache: false,
    Angioedema: false,
    Cardiac_dysrhythmia: false,
    Cough: false,
    Skin_rash: false,
    Hepatic_toxicity: false,
    Wheal: false,
    Diarrhea: false,
    Unknown: false,
    Dystonia: false,
    Fever: false,
    Flushing: false,
    Gastrointestinal_irritation: false,
    High_blood_pressure: false,
    Itching_of_skin: false,
    Musculoskeletal_pain: false,
    Myalgia: false,

  })
  useEffect(() => {
    const updatedCheckData = { ...reactions };

    // Update the boolean values in checkData based on qualities
    props.data.reaction.length > 0 && props.data.reaction.forEach((quality) => {
      let propertyName = quality;
      if (propertyName && updatedCheckData.hasOwnProperty(propertyName)) {
        updatedCheckData[propertyName] = true;
      }
      else {
        console.log(propertyName, "propertyName")
        setOtherVal(propertyName)
      }

    });
    setReactions(updatedCheckData)
  }, [props.data])
  useEffect(() => {
    if (otherVal !== "") {
      setOther(true)
    }
  }, [otherVal])
  useEffect(() => {
    let updatedArray = [];
    for (var key in reactions) {
      if (reactions[key] === true) {
        updatedArray.push(key);
      }
    }
    setReactionArray(updatedArray);


  }, [reactions]);
  const handlereactions = (e) => {
    const key = e.target.name;

    setReactions((currentstate) => ({
      ...currentstate,
      [key]: !reactions[key],
    })
    );

  };
  useEffect(() => {

    const handleKey = (e) => {
      if (e.altKey && e.key === 's') {
        e.preventDefault()
        saveButtonRef.current.click()
      }
    }
    document.addEventListener("keydown", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
    }

  }, []);


  //   useEffect(() => {
  //     inputRefs.current[0].focus();  
  //     inputRefs.current[0].selectionStart = inputRefs.current[0].selectionEnd = inputRefs.current[0].value.length;
  // }, []);

  const handleSave = () => {



    if (allerduration === null || allerduration < 0.1) {
      alert.error("Enter duration!")
    }
    else {
      if (otherVal !== "" && !reactionArray.includes(otherVal)) {
        reactionArray.push(otherVal)
      }

      const tokenString = sessionStorage.getItem('usertoken');

      let str = tokenString.replace(/["]+/g, '');


      const dataToUpdate =
      {

        "patient_id": id,
        "duration": allerduration,
        "reaction": reactionArray,
        "severity": severity,
        "description": description
      }



      axios.put(`${APIURL}/api/v1/doctor/allergies-details/${data.id}/`, dataToUpdate, {
        headers: {

          "Authorization": 'Token ' + str,
          'Content-Type': 'application/json'
        }


      })



        .then(res => {

          if (res.data.status === "success") {
            alert.success("Allergy updated  Successfully ")
            props.handleObs();

          }
          else {

            alert.error("already exist");



          }

        })
        .catch(err => {

          alert.error("error in submission")
        })
    }
  }
  return (
    <>


      <div className="flex-col">

        <div>
          <div className='' style={{ padding: "1%" }}>
            <div className="row">

              <div className='sub-head-div col-9' style={{ textAlign: "left" }}>
                <div className="row">
                  <h6 className="col-8" style={{ padding: "1%", marginTop: "1.5%" }}><b>  {data.master_allergy} </b><span>&nbsp;&nbsp;</span> </h6>
                  <span className="col-4" style={{ fontSize: "16px", paddingTop: "1.5%", backgroundColor: "#5587b573", borderRadius: "3px" }}>{" "}{data.master_allergy_type}{" "}</span>

                </div>
              </div>
              <div className="col-3">

                <input
                  ref={el => inputRefs.current[1] = el}
                  onKeyDown={(e) => handleKeyDown(e, 1)}
                  placeholder="Duration in yrs"
                  type="number"
                  className="enquiryinput"
                  name="duration"
                  value={allerduration}
                  onChange={(e) => {
                    e.preventDefault();
                    setAllerDuration(e.target.value);
                  }}
                ></input>



              </div>
            </div>
            <div className='row' style={{ marginTop: "2%", margin: "auto", textAlign: "left" }}>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Altered_mental_status"
                  name="Altered_mental_status"
                  checked={reactions.Altered_mental_status}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Altered mental status</label></>
              </div>

              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Anemia"
                  name="Anemia"
                  checked={reactions.Anemia}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Anemia</label></>
              </div> <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Headache"
                  name="Headache"
                  checked={reactions.Headache}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Headache </label></>
              </div> <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Anaphylactic_shock"
                  name="Anaphylactic_shock"
                  checked={reactions.Anaphylactic_shock}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Anaphylactic shock</label></>
              </div> <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Angioedema"
                  name="Angioedema"
                  checked={reactions.Angioedema}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Angioedema</label></>
              </div> <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Bronchospasm"
                  name="Bronchospasm"
                  checked={reactions.Bronchospasm}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Bronchospasm</label></>
              </div> <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Cardiac_dysrhythmia"
                  name="Cardiac_dysrhythmia"
                  checked={reactions.Cardiac_dysrhythmia}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Cardiac dysrhythmia</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Cough"
                  name="Cough"
                  checked={reactions.Cough}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Cough</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Diarrhea"
                  name="Diarrhea"
                  checked={reactions.Diarrhea}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Diarrhea</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Dystonia"
                  name="Dystonia"
                  checked={reactions.Dystonia}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Dystonia</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Fever"
                  name="Fever"
                  checked={reactions.Fever}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Fever</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Flushing"
                  name="Flushing"
                  checked={reactions.Flushing}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Flushing</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Gastrointestinal_irritation"
                  name="Gastrointestinal_irritation"
                  checked={reactions.Gastrointestinal_irritation}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Gastrointestinal Irritation</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="High_blood_pressure"
                  name="High_blood_pressure"
                  checked={reactions.High_blood_pressure}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>High blood pressure</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Itching_of_skin"
                  name="Itching_of_skin"
                  checked={reactions.Itching_of_skin}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Itching of skin</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Musculoskeletal_pain"
                  name="Musculoskeletal_pain"
                  checked={reactions.Musculoskeletal_pain}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Musculoskeletal pain</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Myalgia"
                  name="Myalgia"
                  checked={reactions.Myalgia}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Myalgia</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Skin_rash"
                  name="Skin_rash"
                  checked={reactions.Skin_rash}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Skin rash</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Hepatic_toxicity"
                  name="Hepatic_toxicity"
                  checked={reactions.Hepatic_toxicity}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Hepatic toxicity</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Wheal"
                  name="Wheal"
                  checked={reactions.Wheal}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Wheal</label></>
              </div>
              <div className='col-6' >
                <><input
                  className=""
                  type="checkbox"
                  id="Unknown"
                  name="Unknown"
                  checked={reactions.Unknown}
                  onChange={(e) => { handlereactions(e) }}
                />{" "}<label className="" style={{ marginTop: "2%" }}>Unknown</label></>
              </div>
              <div className='col-6' >
                {!other ?
                  <><input
                    className=""
                    type="checkbox"
                    id="Other"
                    name="Other"


                    onClick={(e) => { setOther(true) }}
                  />{" "}<label className="" style={{ marginTop: "2%" }}>Other</label></> :
                  <div style={{ display: "flex" }}><input className='enquiryinput'
                    maxLength="200"
                    type="text"
                    name="action_planned"
                    value={otherVal}
                    onChange={(e) => {
                      let val = e.target.value
                      setOtherVal(val)
                    }}
                  />
                    <i onClick={(e) => { setOtherVal(""); setOther(false) }} class="fa fa-times" aria-hidden="true"></i>
                  </div>}

              </div>

            </div>
          </div>

          <div className='sub-head-div' style={{ textAlign: "left" }}>
            <h6 style={{ padding: "1%" }}>Severity </h6>
          </div>
          <br />
          <div className="row" style={{ margin: "auto" }}>
            <div className="col-4" >
              <><input
                className=""
                type="radio"
                checked={severity === "moderate"}
                onChange={(e) => { setSeverity("moderate") }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Moderate</label></>
            </div>
            <div className="col-4" >
              <><input
                className=""
                type="radio"
                checked={severity === "mild"}
                onChange={(e) => { setSeverity("mild") }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Mild</label></>
            </div>
            <div className="col-4" >
              <><input
                className=""
                type="radio"
                checked={severity === "severe"}
                onChange={(e) => { setSeverity("severe") }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Severe</label></>
            </div>


            <div className="col-12">

              <textarea

                onKeyDown={(e) => handleKeyDown(e, 1)}
                placeholder="Description"
                type="text"
                className="enquiryinputtext"
                style={{ height: "100px", margin: "1%" }}
                value={description}
                onChange={(e) => {
                  e.preventDefault();
                  setDescription(e.target.value);
                }}
              ></textarea>



            </div>

          </div><br />

          <div className="form-button">
            <button
              ref={saveButtonRef}
              type="submit" className="review-button"
              onClick={handleSave}
            >
              {" "}
              Save{" "}
            </button>
          </div>
        </div>






      </div>


      {/* <div className='flex-col'>        

                <textarea 
                disabled
                ref={el => inputRefs.current[0] = el}
                onKeyDown={(e) => handleKeyDown(e,0)}
                type="text" 
                className="form-group text-area remark-add"
                value= {obsData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setObsData(e.target.value)
          } }
            autoComplete="off"
                />

<div>
        <span className="align-rental">Duration : </span>{" "}
        <input
          ref={el => inputRefs.current[1] = el}
          onKeyDown={(e) => handleKeyDown(e,1)}
          type="number"
          className="form-control title-flex"
          name="duration"
          value={allerduration}
          onChange={(e) => {
            e.preventDefault();
            setAllerDuration(e.target.value);
          }}
        ></input>
        <br />
        <br />{" "}
     
      </div>
            <button 
            ref={saveButtonRef}
            // disabled={obsData=== data.allergy} 
            onClick={handleSave} className='btn btn-primary btn-col'>Save</button>





           

        </div> */}

    </>

  )
}

export default EditAller
