import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import bed from "../../../assets/images/icons/bed.png";
import ward from "../../../assets/images/icons/ward.png";
import { APIURL,TZ } from "../../../Global";
import { useAlert } from "react-alert";
import axios from "axios";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { NavDropdown } from "react-bootstrap";
import FloorDetails from "./FloorDetails";
import BedDetails from "./BedDetails";

function AssignRoomPat(props) {
  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [floors, setFloors] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [bedUnitCode, setBedUnitCode] = useState("");
  const [assigned, setAssigned] = useState(false)
  const [bedspaceType,setbedspaceType]=useState()
  const [refresh, setRefresh] = useState(false);
 
  useEffect(() => {

    if (selectedBuilding !== "") {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");

      axios
        .get(`${APIURL}/api/v1/service-provider/floor/?building=${selectedBuilding}&${TZ}`, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setFloors(res.data);
            setSelectedFloor(res.data[0].id)
            if(res.data.length===0){
              setSelectedFloor("")
              setBedUnitCode("")
              setAssigned(false)
              setbedspaceType("")
            }


          }
        });
    }
  }, [selectedBuilding]);
    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
    
        axios
          .get(`${APIURL}/api/v1/service-provider/building/?${TZ}`, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setBuildings(res.data);
              setSelectedBuilding(res.data[0].id);
    
            }
          });
      }, []);
      return(
        <>
    <div className="row" style={{ margin: "1%" }}>
      {props.from==="admission"&&<div className="col-12 row">
        <div className="col-8"></div>
        <div className="col-4">
        <BedDetails bedUnitCode={bedUnitCode} assigned={assigned} bedSpace={bedspaceType} onSuccess={() => {setRefresh(!refresh)}} propsvar={props}/>
        </div>
        </div>}
      <div className= {props.from==="admission"?"col-5":"col-2"}>
        <h5><b>Facility Assignment</b></h5>
        <SimpleBar style={{ maxHeight: '500px' }}>
          {buildings.map(
            (building, index) =>
              <>
                <button
                  style={{
                    textTransform: "capitalize",
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    border: "0.5px solid #2B69A3",
                    borderRadius: "10px",
                    marginBottom: "1%",
                    padding: "3%",
                    flexShrink: "0",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    textAlign: "left",
                    color:
                      selectedBuilding === building.id
                        ? "white"
                        : "#2B69A3",
                    backgroundColor:
                      selectedBuilding === building.id ? "#2B69A3" : "white",



                  }}
                  key={building.id}

                  onClick={() =>
                    setSelectedBuilding(building.id)
                  }
                ><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 20 20"><path fill="currentColor" d="M6.75 6.5a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5m.75 2.25a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0m-.75 3.75a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5m3.75-6.75a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0M9.75 9.5a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5m.75 2.25a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0m2.25.75a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5M4.5 18a.5.5 0 0 1-.5-.5v-14A1.5 1.5 0 0 1 5.5 2h6A1.5 1.5 0 0 1 13 3.5V8h1.5A1.5 1.5 0 0 1 16 9.5v8a.5.5 0 0 1-.5.5zM5 3.5V17h2v-2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5V17h2V9.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0-.5.5M12 15h-1.5v2H12zm-2.5 0H8v2h1.5z" /></svg>&nbsp;&nbsp;
                  {building.build_name}

                </button>
{selectedBuilding===building.id&&floors.length>0&& <SimpleBar style={{ maxHeight: '500px',marginBottom:"1%" }}>
{floors.map(
  (floor, index) =>
    <>
      <button
        style={{
          textTransform: "capitalize",
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '95%',
          border: "0.5px solid #2B69A3",
          borderRadius: "10px",
          marginBottom: "1%",
          marginLeft:"5%",
          float:"right",
          padding: "3%",
          flexShrink: "0",
          fontSize: "14px",
          fontFamily: "Poppins",
          textAlign: "left",
          backgroundColor:
          selectedFloor === floor.id? "#E6EEF8" : "white",



        }}
        key={floor.id}

        onClick={() =>
          setSelectedFloor(floor.id)
        }
      > <span>{floor.floor_name}</span> <div style={{float:"right"}} className="">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="green"><path stroke="green" stroke-linecap="round" stroke-linejoin="round" d="M21.5 18.5v-11a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v11m-3 0v-8h-7a1 1 0 0 0-1 1v7"/><path stroke="green" stroke-linecap="round" stroke-linejoin="round" d="M3.5 10.5h18v3h-19v-2a1 1 0 0 1 1-1"/><path fill="green" d="M10.5 12.5v-1a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1"/></g></svg>
      &nbsp;
            <span>{floor.available} </span>
      </div>
     

      </button></>

)}
</SimpleBar>}
</>
          )}
        </SimpleBar>

       
      </div>
      <div className={props.from==="admission"?"col-7":"col-6"}>
     {floors.length>0&& <FloorDetails floor={selectedFloor} bedUnitCode={setBedUnitCode} assigned={setAssigned} bedSpace={setbedspaceType} refresh={refresh} propsvar={props}/>}
       
      </div>
      <div className="col-4">
     {floors.length>0&&bedUnitCode!==""&&!props.from&& <BedDetails bedUnitCode={bedUnitCode} assigned={assigned} bedSpace={bedspaceType} onSuccess={() => {setRefresh(!refresh)}} propsvar={props}/>}
       
      </div>
    </div>
    </>
      )
}
export default AssignRoomPat;